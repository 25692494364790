<template>
	<el-drawer :append-to-body="true" :wrapperClosable="false" title="查看客户信息" :visible.sync="drawer_" destroy-on-close
		direction="rtl" custom-class="demo-drawer" ref="drawer" size="100%" @open="open" @close="() => {
			isData = true;
			$emit('closeVisible');
		}
			">

		<el-dialog :append-to-body="true" top='15vh' class='diytc' :visible.sync="qsjl" width="95%">

			<div class="tcinfobox">

				<div class="table-header clear">
					<p class="table-title" style="padding-left:0">当前欠税记录</p>
				</div>
				<el-table :header-cell-style="{
					background: '#ecf5ff',
				}" :data="swinfo.cur_owing_taxes_detail" border style="width: fit-content">
					<el-table-column :width="flexColumnWidth(swinfo.cur_owing_taxes_detail, 'levy_project_name', 380, 80)"
						prop="levy_project_name" label="征收项目"></el-table-column>
					<el-table-column width="100" prop="begin_date" label="所属时期起"></el-table-column>
					<el-table-column width="100" prop="end_date" label="所属时期止"></el-table-column>
					<el-table-column width="120" prop="supplement_tax_amount" label="应补税额"></el-table-column>
					<el-table-column width="120" prop="payment_limit_date" label="缴款期限"></el-table-column>
				</el-table>
			</div>


		</el-dialog>
		<el-dialog top='15vh' :append-to-body="true" bclass='diytc' :visible.sync="swcfjl" width="95%">
			<div class="tcinfobox">
				<div class="table-header clear">
					<p class="table-title" style="padding-left:0">税务处罚记录</p>
				</div>
				<el-table :header-cell-style="{
					background: '#ecf5ff',
				}" :data="swinfo.tax_penalty" border style="width: fit-content">

					<el-table-column width="100" prop="lian_date" label="立案日期"></el-table-column>
					<el-table-column width="120" prop="status" label="违法违章状态"></el-table-column>
					<el-table-column width="120" prop="info" label="违法违章信息"></el-table-column>
					<el-table-column :width="flexColumnWidth(swinfo.tax_penalty, 'detail', 550, 80)" prop="detail"
						label="违法违章事实"></el-table-column>
				</el-table>
			</div>


		</el-dialog>
		<el-dialog top='15vh' :append-to-body="true" class='diytc' :visible.sync="zljjl" width="95%">

			<div class="tcinfobox">

				<div class="table-header clear">
					<p class="table-title" style="padding-left:0">滞纳金记录</p>
				</div>
				<el-table :header-cell-style="{
					background: '#ecf5ff',
				}" :data="swinfo.overdue_fine" border style="width: fit-content">
					<el-table-column width="220" prop="tax_time" label="滞纳金时间"></el-table-column>
					<el-table-column width="140" prop="tax_paid" label="滞纳金金额（元）"></el-table-column>
				</el-table>
			</div>


		</el-dialog>
		<div class="demo-drawer__content" style="width: 100%">
			<div v-loading="!allData.clientBase" class="edit" style="padding: 0 0.1rem; width: 100%">
				<div v-if="isData">
					<p style="
							color: #3e31ff;
							font-weight: 800;
							margin: 10px 0;
							font-size: 20px;
						" v-if="allData.clientBase && allData.clientBase.clientName
							">
						客户名称:{{
							allData.clientPresidentRelatedVO
							? allData.clientPresidentRelatedVO
								.whetherSensitive == 1
								? allData.clientBase.pdfClientName
								: allData.clientBase.clientName
							: allData.clientBase.pdfClientName
						}}
					</p>
					<el-form class="form1" v-if="allData.financeRequirementList">
						<p class="t1" style="
								color: #3e31ff;
								font-weight: 800;
								margin: 10px 0;
								font-size: 20px;
							" v-if="allData.financeRequirementList.length">
							<span class="t1-1">1.</span> 融资需求
						</p>
						<template v-if="allData.financeRequirementList">
							<div v-for="(
									item, index
								) in allData.financeRequirementList" :key="index">
								<p class="t2" v-if="allData.financeRequirementList.length >
									1
									">
									<span class="t1-1">1.{{ index + 1 }}.</span>
									融资需求
									{{ index + 1 }}
								</p>
								<p class="t3">
									<span class="t1-1-1">{{
										allData.financeRequirementList
											.length > 1
										? '1.'
										: ''
									}}{{ index + 1 }}.1.</span>
									基础信息
								</p>
								<el-form-item v-if="item.loanMainstay" label="贷款主体: ">
									{{ item.loanMainstay }}</el-form-item>
								<el-form-item v-if="item.minExpectRate || item.requirementRateSelectName" label="期望利率(%): ">
									<span v-if="item.minExpectRate">
										{{ item.minExpectRate }}至{{
											item.maxExpectRate
										}}
									</span>{{ item.requirementRateSelectName }}</el-form-item>
								<el-form-item v-if="item.minFinancing || item.requirementAmountSelectName" label="需求金额(万元): ">
									<span v-if="item.minFinancing">
										{{ item.minFinancing }}至{{
											item.maxFinancing
										}}
									</span>{{ item.requirementAmountSelectName }}</el-form-item>
								<el-form-item v-if="item.loanTypeName" label="有无抵押物: ">
									{{ item.loanTypeName }}</el-form-item>
								<el-form-item v-if="item.minLoanTerm || item.requirementTermSelectName" label="贷款期限(月): ">
									<span v-if="item.minLoanTerm">{{ item.minLoanTerm }}至{{
										item.maxLoanTerm
									}} </span> {{ item.requirementTermSelectName }}</el-form-item>
								<el-form-item v-if="item.paymentPurpose" label="贷款用途: ">
									{{ item.paymentPurpose }}</el-form-item>
								<el-form-item v-if="item.otherRemark" label="融资需求补充说明: ">
									<div class="info" v-html="fn(item.otherRemark || '')"></div>
								</el-form-item>
								<el-form-item v-if="item.availableBankBusinessName" label="可提供的银行价值业务: ">
									{{
										item.availableBankBusinessName
									}}</el-form-item>
								<el-form-item v-if="item.availableOtherControlMeasures" label="可提供的其他风控措施: ">
									<div class="info" v-html="fn(
										item.availableOtherControlMeasures ||
										''
									)
										"></div>
								</el-form-item>
								<el-form-item v-if="(item.reqRelevantAttachment &&
									privacyType == 2) ||
									(allData.checkedTitleField.includes(
										'financeRequirement_reqRelevantAttachment'
									) &&
										privacyType == 1)
									" label="附件:" style="width: 100%">
									<el-upload style="
											line-height: 0;
											margin-left: 50px;
										" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
										:on-preview="handelOnChange" multiple :file-list="item.reqRelevantAttachment">
									</el-upload>
								</el-form-item>
								<p class="t3" v-if="item.personalGuaranteeChecked ||
									item.companyGuaranteeChecked ||
									item.powerPledgeChecked ||
									item.equipmentMortgageChecked ||
									item.othersChecked ||
									item.houseMortgageChecked
									">
									<span class="t1-1-1">{{
										allData.financeRequirementList
											.length > 1
										? '1.'
										: ''
									}}{{ index + 1 }}.2.</span>担保方式
								</p>
								<template v-if="item.houseMortgageChecked">
									<div style="margin-left: 30px" v-for="(
											hitem, hindex
										) in item.houseMortgageList" :key="'h' + hindex">
										<p class="t2">
											房产抵押
											<span v-if="item.houseMortgageList
												.length > 1
												">{{ hindex + 1 }}</span>
										</p>
										<div class="running-info" v-if="item.houseMortgageChecked">
											<el-form-item v-if="hitem.assetName" label="资产名称: ">
												{{
													hitem.assetName
												}}</el-form-item>

											<el-form-item v-if="hitem.borrowerRelationshipHouseMortgageName
													">
												<span style="
														font-weight: 400;
														position: relative;
														z-index: 1;
													" slot="label">与借款人关系
													<el-tooltip effect="dark" placement="right">
														<span v-html="'1类关系人</br>&nbsp;&nbsp;借款企业本身、借款人本身、法人、股东、以上自然人的直系亲属</br>2类关'"
															slot="content"></span>
														<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
												{{
													hitem.borrowerRelationshipHouseMortgageName
												}}
											</el-form-item>
											<el-form-item v-if="hitem.realEstateTypesName" label="不动产类型: ">
												{{
													hitem.realEstateTypesName
												}}</el-form-item>
											<el-form-item v-if="hitem.measureArea" label="面积(㎡): ">
												{{
													hitem.measureArea
												}}</el-form-item>
											<el-form-item v-if="hitem.marketReferencePrice
													" label="市场参考价(万元): ">
												{{
													hitem.marketReferencePrice
												}}</el-form-item>
											<el-form-item v-if="hitem.guidancePrice" label="指导价(万元): ">
												{{
													hitem.guidancePrice
												}}</el-form-item>
											<el-form-item v-if="hitem.propertyOwner" label="权利人(份额): ">
												{{
													hitem.propertyOwner
												}}</el-form-item>
											<el-form-item v-if="hitem.housePropertyCardDate
													" label="房产证日期: ">
												{{
													hitem.housePropertyCardDate
												}}</el-form-item>
											<el-form-item v-if="hitem.usefulLife" label="使用期限(年): ">
												{{
													hitem.usefulLife
												}}</el-form-item>
											<el-form-item v-if="hitem.loanBank" label="贷款银行: ">
												{{
													hitem.loanBank
												}}</el-form-item>
											<el-form-item v-if="hitem.loanBalance" label="贷款余额(万元): ">
												{{
													hitem.loanBalance
												}}</el-form-item>
											<el-form-item v-if="hitem.lendingRate" label="贷款利率(%): ">
												{{
													hitem.lendingRate
												}}</el-form-item>
											<el-form-item v-if="hitem.assetStatusName" label="资产状况: ">
												{{
													hitem.assetStatusName
												}}</el-form-item>
										</div>
									</div>
								</template>
								<template v-if="item.personalGuaranteeChecked">
									<div style="margin-left: 30px" v-for="(pitem, pindex) in JSON.parse(
										item.personalGuaranteeStr
									)" :key="'p' + pindex">
										<p class="t2">
											个人保证
											<span v-if="hideIndex(
												item.personalGuaranteeStr
											)
												">{{ pindex + 1 }}</span>
										</p>
										<div v-if="item.personalGuaranteeChecked">
											<el-form-item v-if="pitem.namePersonalGuarantee
												" label="姓名: ">{{
		pitem.namePersonalGuarantee
	}}
											</el-form-item>
											<el-form-item v-if="pitem.borrowerRelationshipPersonalGuaranteeName
													" label="与借款人关系: ">
												{{
													pitem.borrowerRelationshipPersonalGuaranteeName
												}}</el-form-item>
											<el-form-item v-if="pitem.guaranteeAnalysisPersonalGuarantee
													" label="担保能力分析: ">
												<div class="info" v-html="fn(
													pitem.guaranteeAnalysisPersonalGuarantee ||
													''
												)
													"></div>
											</el-form-item>
										</div>
									</div>
								</template>
								<template v-if="item.companyGuaranteeChecked">
									<div style="margin-left: 30px" v-for="(citem, cindex) in JSON.parse(
										item.companyGuaranteeStr
									)" :key="'c' + cindex">
										<p class="t2">
											公司保证
											<span v-if="hideIndex(
												item.companyGuaranteeStr
											)
												">{{ cindex + 1 }}</span>
										</p>
										<div v-if="item.companyGuaranteeChecked">
											<el-form-item v-if="citem.nameCompanyGuarantee
												" label="公司名称: ">
												{{
													citem.nameCompanyGuarantee
												}}</el-form-item>
											<el-form-item v-if="citem.numberCompanyGuarantee
													" label="公司信用代码: ">
												{{
													citem.numberCompanyGuarantee
												}}</el-form-item>
											<el-form-item v-if="citem.borrowerRelationshipCompanyGuaranteeName
													" label="与借款人关系: ">
												{{
													citem.borrowerRelationshipCompanyGuaranteeName
												}}</el-form-item>
											<el-form-item v-if="item.guaranteeAnalysisCompanyGuarantee
													" label="担保能力分析: ">
												<div class="info" v-html="fn(
													item.guaranteeAnalysisCompanyGuarantee ||
													''
												)
													"></div>
											</el-form-item>
										</div>
									</div>
								</template>
								<template v-if="item.powerPledgeChecked">
									<div style="margin-left: 30px" v-for="(psitem, pcindex) in JSON.parse(
										item.powerPledgeStr
									)" :key="'pc' + pcindex">
										<p class="t2">
											权利质押
											<span v-if="hideIndex(
												item.powerPledgeStr
											)
												">{{ pcindex + 1 }}</span>
										</p>
										<div v-if="item.powerPledgeChecked">
											<el-form-item v-if="psitem.powerContentPowerPledge
												" label="权利内容: ">
												<div class="info" v-html="fn(
													psitem.powerContentPowerPledge ||
													''
												)
													"></div>
											</el-form-item>

											<el-form-item v-if="psitem.remarkPowerPledge" label="备注: ">
												<div class="info" v-html="fn(
													psitem.remarkPowerPledge ||
													''
												)
													"></div>
											</el-form-item>
										</div>
									</div>
								</template>
								<template v-if="item.equipmentMortgageChecked">
									<div style="margin-left: 30px" v-for="(eitem, eindex) in JSON.parse(
										item.equipmentMortgageStr
									)" :key="'e' + eindex">
										<p class="t2">
											设备抵押
											<span v-if="hideIndex(
												item.equipmentMortgageStr
											)
												">{{ eindex + 1 }}</span>
										</p>
										<el-form-item v-if="eitem.deviceInformationEquipmentMortgage
											" label="设备信息: ">
											<div class="info" v-html="fn(
												eitem.deviceInformationEquipmentMortgage ||
												''
											)
												"></div>
										</el-form-item>

										<el-form-item v-if="eitem.remarkEquipmentMortgage" label="备注: ">
											<div class="info" v-html="fn(
												eitem.remarkEquipmentMortgage ||
												''
											)
												"></div>
										</el-form-item>
									</div>
								</template>
								<template v-if="item.othersChecked">
									<div style="margin-left: 30px" v-for="(oitem, oindex) in JSON.parse(
										item.othersStr
									)" :key="'o' + oindex">
										<p class="t2">其他</p>
										<el-form-item v-if="oitem.otherDescOthers" label="其他描述: ">
											<div class="info" v-html="fn(
												oitem.otherDescOthers ||
												''
											)
												"></div>
										</el-form-item>

										<el-form-item v-if="oitem.remarkOthers" label="备注: ">
											<div class="info" v-html="fn(oitem.remarkOthers || '')
												"></div>
										</el-form-item>
									</div>
								</template>

								<p class="t3" v-if="item.recommendReason ||
									item.consultKeyPoint
									">
									<span class="t1-1-1">{{
										allData.financeRequirementList
											.length > 1
										? '1.'
										: ''
									}}{{ index + 1 }}.3.</span>推荐理由
								</p>
								<el-form-item v-if="item.recommendReason" style="margin-left: 20px" label="推荐原因: ">
									<div class="info" v-html="fn(item.recommendReason || '')"></div>
								</el-form-item>
								<el-form-item v-if="item.consultKeyPoint" style="margin-left: 20px" label="咨询要点: ">
									<div class="info" v-html="fn(item.consultKeyPoint || '')"></div>
								</el-form-item>
							</div>
						</template>
						<p class="t1" v-if="allData.clientBase.companyNatureName ||
							allData.clientBase.hasOwnProperty(
								'registeredCapital'
							) ||
							allData.clientBase.belongIndustryName ||
							allData.clientBase.startDate ||
							allData.clientBase.staffSize ||
							allData.otherKeyInfo.employeeNumber ||
							allData.litigationsList.length ||
							allData.clientBase.creditRecord ||
							allData.clientBase.punishRecord ||
							allData.clientBase.otherRecord ||
							allData.clientBase.businessRisksRecord ||
							allData.clientBase.baseBankName ||
							allData.clientBase.settleBank ||
							allData.clientBase.payBank ||
							allData.clientBase.monthPayAmount ||
							allData.clientBase.intangibleAssets ||
							allData.shareholders.length
							">
							<span class="t1-2">2.</span> 客户基本信息
						</p>
						<p class="t2" v-if="allData.clientBase.companyNatureName ||
							allData.clientBase.businessModel ||
							allData.clientBase.hasOwnProperty(
								'registeredCapital'
							) ||
							allData.clientBase.capitalMarketName ||
							allData.clientBase.belongIndustryName ||
							allData.clientBase.startDate ||
							allData.clientBase.staffSize ||
							allData.clientBase.insuredNum
							">
							<span class="t1-2">2.1.</span> 工商信息
						</p>
						<el-form-item v-if="allData.clientBase.companyNatureName" label="企业性质: ">{{
							allData.clientBase &&
							allData.clientBase.companyNatureName
						}}
						</el-form-item>

						<el-form-item v-if="allData.clientBase.businessModelName" label="商业模式: ">{{
							allData.clientBase &&
							allData.clientBase.businessModelName
						}}
						</el-form-item>

						<el-form-item v-if="allData.clientBase.hasOwnProperty(
									'registeredCapital'
								)
								" label="注册资本(万元): ">{{
			allData.clientBase &&
			allData.clientBase.registeredCapital
		}}
						</el-form-item>

						<el-form-item v-if="allData.clientBase.belongIndustryName" label="所属行业: ">{{
							allData.clientBase &&
							allData.clientBase.belongIndustryName
						}}
						</el-form-item>

						<el-form-item v-if="allData.clientBase.startDate" label="成立日期: ">{{
							allData.clientBase &&
							allData.clientBase.startDate
						}}
						</el-form-item>

						<el-form-item v-if="allData.clientBase.staffSize" label="人员规模: ">{{
							allData.clientBase &&
							allData.clientBase.staffSize
						}}
						</el-form-item>

						<el-form-item v-if="allData.otherKeyInfo.hasOwnProperty(
									'employeeNumber'
								)
								" label="社保人数（人）: ">
							{{ allData.otherKeyInfo.employeeNumber }}
						</el-form-item>
						<el-form-item v-if="allData.clientBase.capitalMarketName" label="资本市场状况: ">{{
							allData.clientBase &&
							allData.clientBase.capitalMarketName
						}}
						</el-form-item>
						<el-form-item v-if="allData.clientBase.businessScope" label="经营范围: ">{{
							allData.clientBase &&
							allData.clientBase.businessScope
						}}
						</el-form-item>
						<el-form-item v-if="allData.clientBusinessVO.productIntroduction" label="主营业务: ">{{
							allData.clientBase &&
							allData.clientBusinessVO.productIntroduction
						}}
						</el-form-item>
						<el-form-item style="width: 100%" v-if="allData.clientBase &&
								allData.clientBase.capitalizeAttachment &&
								privacyType == 2
								" label="附件:">
							<el-upload disabled style="line-height: 0; margin-left: 50px" name="files"
								:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-preview="handelOnChange" multiple
								:file-list="allData.clientBase.capitalizeAttachment
									">
							</el-upload>
						</el-form-item>
						<p class="t2" v-if="allData.litigationsList.length">
							<span class="t1-2">2.2.</span> 历史诉讼信息
						</p>

						<el-table v-if="allData.litigationsList.length" :header-cell-style="{ background: '#ecf5ff' }"
							:data="allData.litigationsList" border style="width: 100%">
							<el-table-column prop="caseName" label="案件名称">
							</el-table-column>
							<el-table-column prop="litigationTypeName" label="诉讼人类型" width="100">
							</el-table-column>

							<el-table-column align="right" label="案件金额(万元)" width="120">
								<template slot-scope="scope">
									{{ formatCurrency(scope.row.caseAmount) }}
								</template>
							</el-table-column>
							<el-table-column prop="caseStatusName" label="案件状态" width="80">
							</el-table-column>

							<el-table-column prop="caseAction" label="案由">
							</el-table-column>
							<el-table-column prop="remark" label="案件概述">
							</el-table-column>
							<el-table-column prop="customerExplanation" label="客户解释">
							</el-table-column>
						</el-table>
						<p v-if="allData.shareholders.length" class="t2">
							<span class="t1-2">2.3.</span> 股东信息
						</p>

						<el-table :header-cell-style="{ background: '#ecf5ff' }" :data="allData.shareholders" border
							style="width: 100%" v-if="allData.shareholders.length">
							<el-table-column prop="shareholder" label="股东及出资信息">
							</el-table-column>

							<el-table-column align="right" label="持股比例(%)">
								<template slot-scope="scope">
									{{
										scope.row.sharesRatio
										? Number(
											scope.row.sharesRatio
										).toFixed(2)
										: ''
									}}
								</template>
							</el-table-column>
							<el-table-column label="认缴出资额(万元)" align="right">
								<template slot-scope="scope">
									{{
										formatCurrency(
											scope.row.subscriptionAmount
										)
									}}
								</template>
							</el-table-column>
							<el-table-column prop="subscriptionDate" label="认缴出资日期">
							</el-table-column>
						</el-table>
						<p v-if="allData.clientBase.creditRecord ||
								allData.clientBase.punishRecord ||
								allData.clientBase.otherRecord ||
								allData.clientBase.businessRisksRecord
								" class="t2">
							<span class="t1-2">2.4.</span> 历史信用信息
						</p>
						<el-form-item v-if="allData.clientBase.creditRecord" label="企业征信记录: ">
							<div v-if="allData.clientBase.creditRecord == 1 ||
								allData.clientBase.creditRecord == 5
								" class="info" v-html="fn(
		allData.clientBase.creditRecordName ||
		''
	)
		"></div>
							<div v-else class="info" v-html="fn(
								allData.clientBase.creditRecordDesc ||
								''
							)
								"></div>
						</el-form-item>
						<el-form-item v-if="allData.clientBase.punishRecord" label="企业行政处罚记录: ">
							<div class="info" v-if="allData.clientBase.punishRecord != 2" v-html="fn(
								allData.clientBase.punishRecordName ||
								''
							)
								"></div>
							<div v-else class="info" v-html="fn(
								allData.clientBase.punishRecordDesc ||
								''
							)
								"></div>
						</el-form-item>
						<el-form-item v-if="allData.clientBase.otherRecord" label="企业其他不良信用记录: ">
							<div class="info" v-if="allData.clientBase.otherRecord != 2" v-html="fn(allData.clientBase.otherRecordName || '')
								"></div>
							<div v-else class="info" v-html="fn(allData.clientBase.otherRecordDesc || '')
								"></div>
						</el-form-item>
						<el-form-item v-if="allData.clientBase.businessRisksRecord" label="重大工商风险记录: ">
							<div class="info" v-if="allData.clientBase.businessRisksRecord != 2
								" v-html="fn(
		allData.clientBase
			.businessRisksRecordName || ''
	)
		"></div>
							<div v-else class="info" v-html="fn(
								allData.clientBase
									.businessRisksRecordDesc || ''
							)
								"></div>
						</el-form-item>
						<el-form-item v-if="allData.clientBase.capitalizeEnterpriseCreditAttachment &&
							privacyType == 2
							" label="企业征信附件:">
							<el-upload style="
															line-height: 0;
															margin-left: 50px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
								:on-preview="handelOnChange" multiple :file-list="allData.clientBase.capitalizeEnterpriseCreditAttachment
									">
							</el-upload>
						</el-form-item>
						<p class="t2" v-if="allData.clientBase.baseBankName ||
							allData.clientBase.settleBankName ||
							allData.clientBase.payBankName ||
							allData.clientBase.monthPayAmount
							">
							<span class="t1-2">2.5.</span> 当前合作银行信息
						</p>
						<el-form-item v-if="allData.clientBase.baseBankName" label="基本账户行: ">
							{{ allData.clientBase.baseBankName }}
						</el-form-item>
						<el-form-item v-if="allData.clientBase.settleBankName" label="主要结算银行: ">{{
							allData.clientBase &&
							allData.clientBase.settleBankName
						}}
						</el-form-item>
						<el-form-item v-if="allData.clientBase.payBankName" label="代发薪银行: ">{{
							allData.clientBase &&
							allData.clientBase.payBankName
						}}
						</el-form-item>
						<el-form-item v-if="allData.clientBase.monthPayAmount" label="月代发薪金额: ">{{
							allData.clientBase &&
							allData.clientBase.monthPayAmount
						}}<span v-if="allData.clientBase.monthPayAmount">(万元)</span>
						</el-form-item>
						<p class="t2" v-if="allData.clientBase.intangibleAssets">
							<span class="t1-2">2.5.</span> 其他关键信息
						</p>
						<el-form-item v-if="allData.clientBase.intangibleAssets" label="企业核心无形资产: ">
							<div class="info" v-html="fn(
								allData.clientBase.intangibleAssets ||
								''
							)
								"></div>
						</el-form-item>
						<p class="t1" v-if="allData.otherKeyInfo &&
							(allData.otherKeyInfo
								.mainBusinessIncomeTwoAmount ||
								allData.otherKeyInfo
									.taxDeclarationIncomeTwoAmount ||
								allData.otherKeyInfo
									.mainBusinessIncomeOneAmount ||
								allData.otherKeyInfo
									.taxDeclarationIncomeOneAmount ||
								allData.otherKeyInfo
									.mainBusinessIncomeThisAmount ||
								allData.otherKeyInfo
									.taxDeclarationIncomeThisAmount ||
								allData.otherKeyInfo
									.totalTaxPaymentTwoAmount ||
								allData.otherKeyInfo.netProfitOneAmount ||
								allData.otherKeyInfo
									.totalTaxPaymentOneAmount ||
								allData.otherKeyInfo.netProfitThisAmount ||
								allData.otherKeyInfo
									.totalTaxPaymentThisAmount ||
								allData.otherKeyInfo.hasOwnProperty(
									'employeeNumber'
								) ||
								allData.otherKeyInfo.capitalMarketName ||
								allData.otherKeyInfo
									.highTechEnterpriseQualificationName ||
								allData.otherKeyInfo.taxCreditRatingName ||
								allData.otherKeyInfo.hasOwnProperty(
									'inventionPatent'
								) ||
								allData.otherKeyInfo.hasOwnProperty(
									'utilityModel'
								) ||
								allData.otherKeyInfo.hasOwnProperty(
									'appearanceDesign'
								) ||
								allData.otherKeyInfo.hasOwnProperty(
									'softwareCopyright'
								) ||
								allData.otherKeyInfo
									.otherEnterpriseIntelligence ||
								allData.otherKeyInfo.anyMajorLitigation ||
								allData.otherKeyInfo
									.enterpriseCreditRecord ||
								allData.otherKeyInfo
									.actualControllerScoreName ||
								allData.otherKeyInfo.hasOwnProperty(
									'enterpriseCurrentBankLiabilities'
								) ||
								allData.otherKeyInfo
									.actualControllerCreditRecord ||
								allData.otherKeyInfo
									.actualControllerFamilyEstate ||
								allData.otherKeyInfo.hasOwnProperty(
									'actualControllerOperatingLiabilities'
								) ||
								allData.otherKeyInfo.accountsReceivableName)
							">
							<span class="t1-3">3.</span> 客户重点信息
						</p>
						<div v-if="allData.otherKeyInfo" class="running-info">
							<div class="add-list">
								<el-form-item v-if="allData.otherKeyInfo
									.mainBusinessIncomeTwoAmount
									" label="主营业务收入(元): ">
									{{
										allData.otherKeyInfo
											.mainBusinessIncomeTwoYear
									}}
									年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.mainBusinessIncomeTwoAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.mainBusinessIncomeOneAmount
										" label="主营业务收入(元): ">
									{{
										allData.otherKeyInfo
											.mainBusinessIncomeOneYear
									}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.mainBusinessIncomeOneAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.mainBusinessIncomeThisAmount
										" label="主营业务收入(元): ">{{
			allData.otherKeyInfo
				.mainBusinessIncomeThisYear
		}}年度
									{{
										allData.otherKeyInfo
											.mainBusinessIncomeThisYearMonthName
									}}
									{{
										formatCurrency(
											allData.otherKeyInfo
												.mainBusinessIncomeThisAmount
										)
									}}
								</el-form-item>
							</div>

							<div class="add-list">
								<el-form-item v-if="allData.otherKeyInfo
											.taxDeclarationIncomeTwoAmount
										" label="纳税申报收入(元): ">
									{{
										allData.otherKeyInfo
											.taxDeclarationIncomeTwoYear
									}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationIncomeTwoAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.taxDeclarationIncomeOneAmount
										" label="纳税申报收入(元): ">{{
			allData.otherKeyInfo
				.taxDeclarationIncomeOneYear
		}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationIncomeOneAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.taxDeclarationIncomeThisAmount
										" label="纳税申报收入(元): ">{{
			allData.otherKeyInfo
				.taxDeclarationIncomeThisYear
		}}年度
									{{
										allData.otherKeyInfo
											.taxDeclarationIncomeThisYearMonthName
									}}
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationIncomeThisAmount
										)
									}}
								</el-form-item>
							</div>

							<div class="add-list"></div>

							<div class="add-list">
								<el-form-item v-if="allData.otherKeyInfo
											.totalTaxPaymentTwoAmount
										" label="纳税总额(元): ">
									{{
										allData.otherKeyInfo
											.totalTaxPaymentTwoYear
									}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.totalTaxPaymentTwoAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.totalTaxPaymentOneAmount
										" label="纳税总额(元):">{{
			allData.otherKeyInfo
				.totalTaxPaymentOneYear
		}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.totalTaxPaymentOneAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.totalTaxPaymentThisAmount
										" label="纳税总额(元):">{{
			allData.otherKeyInfo
				.totalTaxPaymentThisYear
		}}年度
									{{
										allData.otherKeyInfo
											.totalTaxPaymentThisYearMonthName
									}}
									{{
										formatCurrency(
											allData.otherKeyInfo
												.totalTaxPaymentThisAmount
										)
									}}
								</el-form-item>
							</div>

							<el-form-item v-if="allData.otherKeyInfo.hasOwnProperty(
										'employeeNumber'
									)
									" label="社保人数（人）: ">
								{{ allData.otherKeyInfo.employeeNumber }}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.capitalMarketName" label="资本市场状况: ">
								{{ allData.otherKeyInfo.capitalMarketName }}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo
								.highTechEnterpriseQualificationName
								" label="高新技术企业资质: ">
								{{
									allData.otherKeyInfo
										.highTechEnterpriseQualificationName
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo
										.specializedNewQualificationName
									" label="专精特新资质: ">
								{{
									allData.otherKeyInfo
										.specializedNewQualificationName
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.taxCreditRatingName" label="纳税信用等级: ">
								{{ allData.otherKeyInfo.taxCreditRatingName }}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.hasOwnProperty(
								'inventionPatent'
							)
								" label="发明专利: ">
								{{ allData.otherKeyInfo.inventionPatent }}项
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.hasOwnProperty(
								'utilityModel'
							)
								" label="实用新型: ">
								{{ allData.otherKeyInfo.utilityModel }}项
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.hasOwnProperty(
								'appearanceDesign'
							)
								" label="外观设计: ">
								{{ allData.otherKeyInfo.appearanceDesign }}项
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.hasOwnProperty(
								'softwareCopyright'
							)
								" label="软件著作权: ">
								{{ allData.otherKeyInfo.softwareCopyright }}项
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo
								.otherEnterpriseIntelligence
								" label="其他企业资质: ">
								{{
									allData.otherKeyInfo
										.otherEnterpriseIntelligence
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.anyMajorLitigationName
									" label="是否有未结重大诉讼: ">
								{{
									allData.otherKeyInfo.anyMajorLitigationName
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo
										.enterpriseCreditRecordName
									" label="企业征信记录: ">
								{{
									allData.otherKeyInfo
										.enterpriseCreditRecordName
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo
										.actualControllerScoreName
									" label="实控人综合评分: ">
								{{
									allData.otherKeyInfo
										.actualControllerScoreName
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.hasOwnProperty(
										'enterpriseCurrentBankLiabilities'
									)
									" label="企业当前银行负债(万元): ">
								{{
									allData.otherKeyInfo
										.enterpriseCurrentBankLiabilities
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo
										.actualControllerCreditRecordName
									" label="实控人征信记录: ">
								{{
									allData.otherKeyInfo
										.actualControllerCreditRecordName
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo
										.actualControllerFamilyEstateName
									" label="实控人家庭房产情况: ">
								{{
									allData.otherKeyInfo
										.actualControllerFamilyEstateName
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.hasOwnProperty(
										'actualControllerOperatingLiabilities'
									)
									" label="实控人个人经营类负债(万元): ">
								{{
									allData.otherKeyInfo
										.actualControllerOperatingLiabilities
								}}
							</el-form-item>
							<el-form-item v-if="allData.otherKeyInfo.accountsReceivableName
									" label="应收账款质押情况: ">
								<div v-html="fn(
									allData.otherKeyInfo
										.accountsReceivableName || ''
								)
									"></div>
							</el-form-item>
							<el-form-item style="width: 100%" v-if="allData.otherKeyInfo.remark" label="备注: ">
								<div class="info" v-html="fn(allData.otherKeyInfo.remark || '')
									"></div>
							</el-form-item>
						</div>
						<p class="t1" v-if="allData.personsList.length">
							<span class="t1-4">4.</span> 关键人信息
						</p>
						<template v-if="allData.personsList">
							<div v-for="(gitem, index) in allData.personsList" :key="'gjr' + index">
								<p class="t2">
									<span class="t1-4">4.1.</span> 关键人-{{
										gitem.personName
									}}
								</p>
								<el-form-item v-if="gitem.controlPersonTypeName" label="关键人类别: ">{{ gitem.controlPersonTypeName }}
								</el-form-item>
								<el-form-item v-if="gitem.sexFlagName" label="性别: ">{{ gitem.sexFlagName }}
								</el-form-item>
								<el-form-item v-if="gitem.nativePlace" label="籍贯: ">{{ gitem.nativePlace }}
								</el-form-item>
								<el-form-item v-if="gitem.maritalFlagName" label="婚姻状况: ">{{ gitem.maritalFlagName }}
								</el-form-item>
								<el-form-item v-if="gitem.hasOwnProperty('age')" label="年龄: ">{{ gitem.age }}
								</el-form-item>
								<el-form-item v-if="gitem.holdOfficeName" label="担任职务: ">{{ gitem.holdOfficeName }}
								</el-form-item>
								<el-form-item v-if="gitem.educationFlagName" label="学历: ">{{ gitem.educationFlagName }}
								</el-form-item>
								<el-form-item v-if="gitem.graduateSchool" label="毕业院校: ">{{ gitem.graduateSchool }}
								</el-form-item>
								<el-form-item v-if="gitem.personCreditRecordName" label="征信记录: ">{{ gitem.personCreditRecordName }}
								</el-form-item>
								<el-form-item v-if="gitem.personCreditRecordDesc" label="征信记录描述: ">{{ gitem.personCreditRecordDesc }}
								</el-form-item>
								<el-form-item v-if="gitem.educationMajor" label="所学专业: ">{{ gitem.educationMajor }}
								</el-form-item>
								<el-form-item v-if="gitem.controllerGlory" label="实际控制人主要荣誉: ">{{ gitem.controllerGlory }}
								</el-form-item>
								<el-form-item v-if="gitem.workExperience" label="主要工作经历: ">{{ gitem.workExperience }}
								</el-form-item>
								<el-form-item v-if="gitem.workGrade" label="主要工作成绩: ">{{ gitem.workGrade }}
								</el-form-item>
								<el-form-item v-if="gitem.controlPersonAttachment &&
									privacyType == 2
									" label="身份证附件	:">
									<el-upload style="
															line-height: 0;
															margin-left: 50px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
										:on-preview="handelOnChange" multiple :file-list="gitem.controlPersonAttachment
											">
									</el-upload>
								</el-form-item>
								<el-form-item v-if="gitem.personalCreditAttachment &&
									privacyType == 2
									" label="个人征信附件:">
									<el-upload style="
															line-height: 0;
															margin-left: 50px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
										:on-preview="handelOnChange" multiple :file-list="gitem.personalCreditAttachment
											">
									</el-upload>
								</el-form-item>
								<el-form-item v-if="gitem.otherAttachment &&
									privacyType == 2
									" label="其他附件:">
									<el-upload style="
															line-height: 0;
															margin-left: 50px;
														" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
										:on-preview="handelOnChange" multiple :file-list="gitem.otherAttachment
											">
									</el-upload>
								</el-form-item>
							</div>
						</template>

						<p class="t1" v-if="allData.personalAssetsList.length">
							<span class="t1-5">5.</span> 资产信息
						</p>
						<template v-if="allData.personalAssetsList.length">
							<div v-for="(
									zzitem, index
								) in allData.personalAssetsList" :key="'zzitem' + index" class="propertyinfo">
								<p class="t2">
									<span class="t1-5">5.1.</span>
									资产名称-{{ zzitem.assetName }}
								</p>
								<el-form-item style="width: 50%" v-if="zzitem.realEstateTypesName" label="不动产类型: ">{{
									zzitem.realEstateTypesName }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.measureArea" label="面积(平方米): ">{{ zzitem.measureArea }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.usefulLife" label="使用期限(年): ">{{ zzitem.usefulLife }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.assetStatusName" label="资产状况: ">{{ zzitem.assetStatusName }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.marketReferencePrice" label="市场参考价(万元): ">{{
									formatCurrency(
										zzitem.marketReferencePrice
									)
								}}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.guidancePrice" label="指导价(万元): ">{{
									formatCurrency(zzitem.guidancePrice) }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.loanBalance" label="贷款余额(万元): ">{{
									formatCurrency(zzitem.loanBalance) }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.loanBank" label="贷款银行: ">{{ zzitem.loanBank }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.lendingRate" label="贷款利率(%): ">{{
									Number(zzitem.lendingRate || 0).toFixed(
										2
									)
								}}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.collateralAddressName" label="抵押物地址: ">{{
									zzitem.collateralAddressName }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.housePropertyCardDate" label="房产证日期: ">{{
									zzitem.housePropertyCardDate }}
								</el-form-item>
								<el-form-item style="width: 50%" v-if="zzitem.transferDate" label="过户日期: ">{{ zzitem.transferDate }}
								</el-form-item>

								<el-form-item style="width: 100%" v-if="zzitem.remarks" label="备注: ">{{ zzitem.remarks }}
								</el-form-item>
								<el-form-item style="width: 100%" v-if="privacyType == 2" label="附件:">
									<template>
										<div v-for="(
												urlitem, urlindex
											) in zzitem.assetAttachment" :key="urlindex" style="color: blue;cursor: pointer;"
											@click="handelOnChange(urlitem)">{{ urlitem.name }}</div>
									</template>
								</el-form-item>
							</div>
						</template>

						<p class="t1" v-if="allData.areasList.length ||
							allData.downstreams.length ||
							allData.upstreams.length ||
							allData.clientBusinessVO.mainSupplier ||
							allData.clientBusinessVO.downClient ||
							allData.clientBusinessVO.productIntroduction ||
							allData.clientBusinessVO.description ||
							allData.clientBusinessVO
								.coreCompetitivenessVista ||
							allData.clientBusinessVO.shortBoardProblems
							">
							<span class="t1-8">8.</span> 经营情况分析
						</p>
						<p class="t2" v-if="allData.areasList.length">
							<span class="t1-8">8.1.</span> 生产经营场所情况
						</p>

						<el-table v-if="allData.areasList.length" :header-cell-style="{ background: '#ecf5ff' }"
							:data="allData.areasList" border style="width: 100%">
							<el-table-column prop="workAddress" label="办公地址">
							</el-table-column>
							<el-table-column prop="workNatureName" label="办公性质">
							</el-table-column>
							<el-table-column prop="workArea" label="办公面积(平方米)">
							</el-table-column>
							<el-table-column prop="placeNatureName" label="场所性质">
							</el-table-column>
							<el-table-column prop="startDate" label="该场所办公起始日">
							</el-table-column>
							<el-table-column prop="endDate" label="该场所租约到期日">
							</el-table-column>
							<el-table-column align="right" label="月租金及管理费(万元)">
								<template slot-scope="scope">
									{{ formatCurrency(scope.row.rentMonth) }}
								</template>
							</el-table-column>
							<el-table-column align="right" label="近六月平均水电费(万元)">
								<template slot-scope="scope">
									{{ formatCurrency(scope.row.averageCost) }}
								</template>
							</el-table-column>
						</el-table>
						<p v-if="allData.downstreams.length" class="t2">
							<span class="t1-8">8.2.</span>
							上年度主要下游客户分析
						</p>

						<el-table v-if="allData.downstreams.length" :header-cell-style="{ background: '#ecf5ff' }"
							:data="allData.downstreams" border style="width: 100%">
							<el-table-column prop="customerName" label="客户名称">
							</el-table-column>
							<el-table-column prop="customerNatureName" label="客户性质">
							</el-table-column>
							<el-table-column prop="saleGoods" label="销售商品名称/类型">
							</el-table-column>
							<el-table-column align="right" label="年度销售金额(万元)">
								<template slot-scope="scope">
									{{ formatCurrency(scope.row.saleAmount) }}
								</template>
							</el-table-column>
							<el-table-column prop="settlementTypeName" label="结算方式">
							</el-table-column>
							<el-table-column prop="settlementStage" label="结算账期">
							</el-table-column>
							<el-table-column prop="cooperationYears" label="合作年限(年)">
							</el-table-column>
							<el-table-column align="right" label="业务量占比(%)">
								<template slot-scope="scope">
									{{
										Number(
											scope.row.businessProportion || 0
										).toFixed(2)
									}}
								</template>
							</el-table-column>
						</el-table>
						<p v-if="allData.upstreams.length" class="t2">
							<span class="t1-8">8.3.</span>
							上年度主要上游客户分析
						</p>

						<el-table v-if="allData.upstreams.length" :header-cell-style="{ background: '#ecf5ff' }"
							:data="allData.upstreams" border style="width: 100%">
							<el-table-column prop="supplierName" label="供应商名称">
							</el-table-column>
							<el-table-column prop="supplierNatureName" label="供应商性质">
							</el-table-column>
							<el-table-column prop="purchaseGoods" label="采购商品名称/类型">
							</el-table-column>
							<el-table-column label="年度采购金额(万元)" align="right">
								<template slot-scope="scope">
									{{
										formatCurrency(scope.row.purchaseAmount)
									}}
								</template>
							</el-table-column>
							<el-table-column prop="settlementTypeName" label="结算方式">
							</el-table-column>
							<el-table-column prop="settlementStage" label="结算账期">
							</el-table-column>
							<el-table-column prop="cooperationYears" label="合作年限(年)">
							</el-table-column>
							<el-table-column label="业务量占比(%)" align="right">
								<template slot-scope="scope">
									{{
										Number(
											scope.row.businessProportion || 0
										).toFixed(2)
									}}
								</template>
							</el-table-column>
						</el-table>
						<p v-if="allData.clientBusinessVO.mainSupplier" class="t2">
							<span class="t1-8">8.4.</span>
							主要供应商情况分析
						</p>

						<div v-if="allData.clientBusinessVO.mainSupplier" class="info" v-html="fn(allData.clientBusinessVO.mainSupplier || '')
							"></div>
						<p v-if="allData.clientBusinessVO.downClient" class="t2">
							<span class="t1-8">8.5.</span>
							主要下游客户情况分析
						</p>

						<div v-if="allData.clientBusinessVO.downClient" class="info" v-html="fn(allData.clientBusinessVO.downClient || '')
							"></div>
						<p v-if="allData.clientBusinessVO.productIntroduction" class="t2">
							<span class="t1-8">8.6.</span> 企业主营产品介绍
						</p>

						<div v-if="allData.clientBusinessVO.productIntroduction" class="info" v-html="fn(
							allData.clientBusinessVO
								.productIntroduction || ''
						)
							"></div>
						<p v-if="allData.clientBusinessVO.description" class="t2">
							<span class="t1-8">8.7.</span>
							企业主要生产流程/业务流程描述
						</p>

						<div v-if="allData.clientBusinessVO.description" class="info" v-html="fn(allData.clientBusinessVO.description || '')
							"></div>
						<p v-if="allData.clientBusinessVO
							.coreCompetitivenessVista
							" class="t2">
							<span class="t1-8">8.8.</span>
							主营业务核心竞争力和未来前景展望
						</p>
						<div>
							<div v-if="allData.clientBusinessVO
								.coreCompetitivenessVista
								" class="info" v-html="fn(
		allData.clientBusinessVO
			.coreCompetitivenessVista || ''
	)
		"></div>
						</div>
						<p v-if="allData.clientBusinessVO.shortBoardProblems" class="t2">
							<span class="t1-8">8.9.</span>
							经营短板和存在问题
						</p>
						<div>
							<div v-if="allData.clientBusinessVO.shortBoardProblems
								" class="info" v-html="fn(
		allData.clientBusinessVO
			.shortBoardProblems || ''
	)
		"></div>
						</div>
						<p class="t1" v-if="getValues[0].value0 ||
							getIncome[0].value0 ||
							getIndicator[0].value0 ||
							allData.clientFinanceVO.financialAnalyse
							">
							<span class="t1-9">9.</span> 主要财务信息及指标
						</p>

						<p class="t2" v-if="getValues[0].value0">
							<span class="t1-9">9.1.</span> 财务信息
						</p>
						<p class="t3" v-if="getValues[0].value0">
							<span class="t1-9-1-1">9.1.1</span>
							资产负债表
						</p>
						<div class="diytable_box diytable_weight" v-if="getValues[0].value0">
							<el-table :row-style="isRed1" class="diytable" :data="getValues" :show-header="false" border>
								<el-table-column v-for="(item, index) in Object.keys(
									getValues[0]
								)" :key="index" :prop="item" :width="item == 'title' ? '220' : '130'" :align="item != 'title' ? 'right' : ''">
								</el-table-column>
							</el-table>
						</div>

						<p class="t3" v-if="getIncome[0].value0">
							<span class="t1-9-1-1">9.1.2</span>
							利润表
						</p>
						<div class="diytable_box diytable_weight1" v-if="getIncome[0].value0">
							<el-table :row-style="isRed1" class="diytable" :data="getIncome" :show-header="false" border>
								<el-table-column v-for="(item, index) in Object.keys(
									getIncome[0]
								)" :key="index" :prop="item" :width="item == 'title' ? '360' : '130'" :align="item != 'title' ? 'right' : ''">
								</el-table-column>
							</el-table>
						</div>
						<p v-if="getIndicator[0].value0" class="t2">
							<span class="t1-9">9.2.</span> 财务指标
						</p>
						<div v-if="getIndicator[0].value0" class="diytable_box allw" style="width: 65%">
							<el-table class="diytable" :row-style="isRed2" :span-method="arraySpanMethod1" :data="getIndicator"
								:show-header="false" border>
								<el-table-column v-for="(item, index) in Object.keys(
									getIndicator[0]
								)" :key="index" :prop="item" :width="item == 'title' ? '150' : ''" :align="item != 'title' ? 'right' : ''">
								</el-table-column>
							</el-table>
						</div>

						<p class="t2" v-if="allData.clientFinanceVO.financialAnalyse ||
							allData.clientFinanceVO.financeNormativeName ||
							allData.clientFinanceVO
								.auditorOfficeQualificationName
							">
							<span class="t1-9">9.3.</span> 财务数据分析
						</p>
						<el-form-item v-if="allData.clientFinanceVO.financeNormativeName" label="财务管理规范性: ">
							{{ allData.clientFinanceVO.financeNormativeName }}
						</el-form-item>
						<el-form-item v-if="allData.clientFinanceVO
							.auditorOfficeQualificationName
							" label="审计师事务所资质: ">
							{{
								allData.clientFinanceVO
									.auditorOfficeQualificationName
							}}
						</el-form-item>
						<el-form-item v-if="allData.clientFinanceVO.financialAnalyse" label="财务指标分析: ">
							<div class="info" v-html="fn(
								allData.clientFinanceVO
									.financialAnalyse || ''
							)
								"></div>
						</el-form-item>
						<p class="t2" v-if="(allData.clientFinanceVO
							.balanceSheetAttachment ||
							allData.clientFinanceVO
								.incomeStatementAttachment ||
							allData.clientFinanceVO
								.cashFlowStatementAttachment ||
							allData.clientFinanceVO
								.taxReturnsAttachment ||
							allData.clientFinanceVO
								.taxPaymentCertificateAttachment) &&
							privacyType == 2
							">
							<span class="t1-9">9.4.</span> 附件
						</p>
						<el-form-item v-if="allData.clientFinanceVO
							.balanceSheetAttachment && privacyType == 2
							" label="资产负债表:">
							<el-upload style="
									line-height: 0;
									width: 50%;
									margin-left: 50px;
								" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
								:on-preview="handelOnChange" multiple :file-list="allData.clientFinanceVO
									.balanceSheetAttachment
									">
							</el-upload>
						</el-form-item>
						<el-form-item v-if="allData.clientFinanceVO
							.incomeStatementAttachment &&
							privacyType == 2
							" label="利润表:">
							<el-upload style="
									line-height: 0;
									width: 50%;
									margin-left: 50px;
								" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
								:on-preview="handelOnChange" multiple :file-list="allData.clientFinanceVO
									.incomeStatementAttachment
									">
							</el-upload>
						</el-form-item>
						<el-form-item v-if="allData.clientFinanceVO
							.cashFlowStatementAttachment &&
							privacyType == 2
							" label="现金流量表:">
							<el-upload style="
									line-height: 0;
									width: 50%;
									margin-left: 50px;
								" disabled name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
								:on-preview="handelOnChange" multiple :file-list="allData.clientFinanceVO
									.cashFlowStatementAttachment
									">
							</el-upload>
						</el-form-item>
						<p class="t1" v-if="allData.otherKeyInfo &&
							(allData.otherKeyInfo
								.taxDeclarationIncomeTwoAmount ||
								allData.otherKeyInfo
									.taxDeclarationIncomeOneAmount ||
								allData.otherKeyInfo
									.taxDeclarationIncomeThisAmount ||
								allData.otherKeyInfo
									.totalTaxPaymentTwoAmount ||
								allData.otherKeyInfo
									.totalTaxPaymentOneAmount ||
								allData.otherKeyInfo
									.totalTaxPaymentThisAmount ||
								allData.otherKeyInfo
									.taxDeclarationProfitTwoAmount ||
								allData.otherKeyInfo
									.taxDeclarationProfitOneAmount ||
								allData.otherKeyInfo
									.taxDeclarationProfitThisAmount ||
								allData.otherKeyInfo.taxAddedTwoAmount ||
								allData.otherKeyInfo.taxAddedOneAmount ||
								allData.otherKeyInfo.taxAddedThisAmount ||
								allData.otherKeyInfo.taxIncomeTwoAmount ||
								allData.otherKeyInfo.taxIncomeOneAmount ||
								allData.otherKeyInfo.taxIncomeThisAmount ||
								allData.otherKeyInfo
									.taxPaymentCertificateAttachment ||
								allData.otherKeyInfo.vatReturnAttachment ||
								allData.otherKeyInfo
									.incomeTaxReturnAttachment ||
								allData.otherKeyInfo.otherAttachment)
							">
							<span class="t1-99">3.</span> 税务信息
						</p>
						<div v-if="allData.otherKeyInfo && !isswbg" class="running-info">
							<div class="add-list" v-if="allData.otherKeyInfo
								.taxDeclarationIncomeTwoAmount ||
								allData.otherKeyInfo
									.taxDeclarationIncomeOneAmount ||
								allData.otherKeyInfo
									.taxDeclarationIncomeThisAmount
								">
								<el-form-item v-if="allData.otherKeyInfo
									.taxDeclarationIncomeTwoAmount
									" label="纳税申报收入(元): ">
									{{
										allData.otherKeyInfo
											.taxDeclarationIncomeTwoYear
									}}
									年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationIncomeTwoAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.taxDeclarationIncomeOneAmount
										" label="纳税申报收入(元): ">
									{{
										allData.otherKeyInfo
											.taxDeclarationIncomeOneYear
									}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationIncomeOneAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.taxDeclarationIncomeThisAmount
										" label="纳税申报收入(元): ">{{
			allData.otherKeyInfo
				.taxDeclarationIncomeThisYear
		}}年度
									{{
										allData.otherKeyInfo
											.taxDeclarationIncomeThisYearMonth
									}}月
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationIncomeThisAmount
										)
									}}
								</el-form-item>
							</div>

							<div class="add-list" v-if="allData.otherKeyInfo
										.totalTaxPaymentTwoAmount ||
									allData.otherKeyInfo
										.totalTaxPaymentOneAmount ||
									allData.otherKeyInfo
										.totalTaxPaymentThisAmount
									">
								<el-form-item v-if="allData.otherKeyInfo
									.totalTaxPaymentTwoAmount
									" label="纳税总额(元): ">
									{{
										allData.otherKeyInfo
											.totalTaxPaymentTwoYear
									}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.totalTaxPaymentTwoAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.totalTaxPaymentOneAmount
										" label="纳税总额(元): ">{{
			allData.otherKeyInfo
				.totalTaxPaymentOneYear
		}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.totalTaxPaymentOneAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.totalTaxPaymentThisAmount
										" label="纳税总额(元): ">{{
			allData.otherKeyInfo
				.totalTaxPaymentThisYear
		}}年度
									{{
										allData.otherKeyInfo
											.totalTaxPaymentThisYearMonth
									}}月
									{{
										formatCurrency(
											allData.otherKeyInfo
												.totalTaxPaymentThisAmount
										)
									}}
								</el-form-item>
							</div>

							<div class="add-list" v-if="allData.otherKeyInfo
										.taxDeclarationProfitTwoAmount ||
									allData.otherKeyInfo
										.taxDeclarationProfitOneAmount ||
									allData.otherKeyInfo
										.taxDeclarationProfitThisAmount
									">
								<el-form-item v-if="allData.otherKeyInfo
									.taxDeclarationProfitTwoAmount
									" label="纳税申报利润(元): ">
									{{
										allData.otherKeyInfo
											.taxDeclarationProfitTwoYear
									}}
									年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationProfitTwoAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.taxDeclarationProfitOneAmount
										" label="纳税申报利润(元):">{{
			allData.otherKeyInfo
				.taxDeclarationProfitOneYear
		}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationProfitOneAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo
											.taxDeclarationProfitThisAmount
										" label="纳税申报利润(元):">{{
			allData.otherKeyInfo
				.taxDeclarationProfitThisYear
		}}年度
									{{
										allData.otherKeyInfo
											.taxDeclarationProfitThisYearMonth
									}}月
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxDeclarationProfitThisAmount
										)
									}}
								</el-form-item>
							</div>

							<div class="add-list" v-if="allData.otherKeyInfo.taxAddedTwoAmount ||
									allData.otherKeyInfo.taxAddedOneAmount ||
									allData.otherKeyInfo.taxAddedThisAmount
									">
								<el-form-item v-if="allData.otherKeyInfo.taxAddedTwoAmount
									" label="增值税(元): ">
									{{
										allData.otherKeyInfo.taxAddedTwoYear
									}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxAddedTwoAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo.taxAddedOneAmount
										" label="增值税(元):">{{
			allData.otherKeyInfo.taxAddedOneYear
		}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxAddedOneAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo.taxAddedThisAmount
										" label="增值税(元):">{{
			allData.otherKeyInfo.taxAddedThisYear
		}}年度
									{{
										allData.otherKeyInfo
											.taxAddedThisYearMonth
									}}月
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxAddedThisAmount
										)
									}}
								</el-form-item>
							</div>

							<div class="add-list" v-if="allData.otherKeyInfo.taxIncomeTwoAmount ||
									allData.otherKeyInfo.taxIncomeOneAmount ||
									allData.otherKeyInfo.taxIncomeThisAmount
									">
								<el-form-item v-if="allData.otherKeyInfo.taxIncomeTwoAmount
									" label="所得税(元): ">
									{{
										allData.otherKeyInfo.taxIncomeTwoYear
									}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxIncomeTwoAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo.taxIncomeOneAmount
										" label="所得税(元):">{{
			allData.otherKeyInfo.taxIncomeOneYear
		}}年度
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxIncomeOneAmount
										)
									}}
								</el-form-item>

								<el-form-item v-if="allData.otherKeyInfo.taxIncomeThisAmount
										" label="所得税(元):">{{
			allData.otherKeyInfo.taxIncomeThisYear
		}}年度
									{{
										allData.otherKeyInfo
											.taxIncomeThisYearMonth
									}}月
									{{
										formatCurrency(
											allData.otherKeyInfo
												.taxIncomeThisAmount
										)
									}}
								</el-form-item>
							</div>

							<el-form-item style="width: 100%" v-if="allData.otherKeyInfo
										.taxPaymentCertificateAttachment &&
									privacyType == 2
									" label="完税证明:">
								<el-upload style="line-height: 0; margin-left: 50px" disabled name="files"
									:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-preview="handelOnChange" multiple
									:file-list="allData.otherKeyInfo
										.taxPaymentCertificateAttachment
										">
								</el-upload>
							</el-form-item>

							<el-form-item style="width: 100%" v-if="allData.otherKeyInfo.vatReturnAttachment" label="增值税纳税申报表:">
								<el-upload style="line-height: 0; margin-left: 50px" disabled name="files"
									:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-preview="handelOnChange" multiple
									:file-list="allData.otherKeyInfo.vatReturnAttachment
										">
								</el-upload>
							</el-form-item>
							<el-form-item style="width: 100%" v-if="allData.otherKeyInfo
								.incomeTaxReturnAttachment
								" label="所得税纳税申报表:">
								<el-upload style="line-height: 0; margin-left: 50px" disabled name="files"
									:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-preview="handelOnChange" multiple
									:file-list="allData.otherKeyInfo
										.incomeTaxReturnAttachment
										">
								</el-upload>
							</el-form-item>
							<el-form-item style="width: 100%" v-if="allData.otherKeyInfo.otherAttachment" label="其他附件:">
								<el-upload style="line-height: 0; margin-left: 50px" disabled name="files"
									:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-preview="handelOnChange" multiple
									:file-list="allData.otherKeyInfo.otherAttachment
										">
								</el-upload>
							</el-form-item>
						</div>

						<template v-if="isswbg" class="running-info c-3-body">
							<p class="t2">
								<span class="t1-99">9.2.</span> <span>开票及税务信息</span>
							</p>

							<div class="running-info dztextl" style="width: 100%;">
								<el-form-item label-width="201px" label="纳税人种类: ">
									{{ swinfo.nsrlx }}
								</el-form-item>
								<el-form-item label-width="201px" label="纳税信用等级: ">
									{{ swinfo.credit_level_prevyr }}
								</el-form-item>
								<el-form-item label-width="201px" label="最早开票时长（月）: ">
									{{ fpinfo.fp_minbilldttd_tmspan }}
								</el-form-item>
								<el-form-item label-width="201px" label="近12个月断票月数（月）: ">
									{{ fpinfo.fp_prev12mths_mths_wo_fp }}
								</el-form-item>
								<el-form-item style="width:100%" label-width="280px" label="近12个月（含2月）最长断票月数（月）: ">
									{{ fpinfo.fp_prev12mths_max_mthsinseries_wo_fp }}
								</el-form-item>
								<el-form-item label-width="201px" label="近3个月开票金额(元): ">
									{{ formatCurrency(fpinfo.fp_prev3mths_tot_amt) }}
								</el-form-item>
								<el-form-item label-width="201px" label="近6个月开票金额(元): ">
									{{ formatCurrency(fpinfo.fp_prev6mths_tot_amt) }}
								</el-form-item>
								<el-form-item label-width="201px" label="近12个月开票金额(元): ">
									{{ formatCurrency(fpinfo.fp_prev12mths_tot_amt) }}
								</el-form-item>
								<el-form-item label-width="201px" label="近24个月开票金额(元): ">
									{{ formatCurrency(fpinfo.fp_prev24mths_tot_amt) }}
								</el-form-item>
								<el-form-item label-width="201px" label="历史有无欠税记录: ">
									{{ swinfo.cnt_of_hist_taxarrearcase && swinfo.cnt_of_hist_taxarrearcase > 0 ? '有' : '无' }}
								</el-form-item>
								<el-form-item label-width="201px" label="当前欠税: ">
									{{ swinfo.cur_owing_taxes_detail && swinfo.cur_owing_taxes_detail.length !== 0 ? '有' : '无' }} <span
										v-if="swinfo.cur_owing_taxes_detail && swinfo.cur_owing_taxes_detail.length !== 0"
										style="color:blue;cursor: pointer;" @click="qsjl = true">详情 ></span>
								</el-form-item>
								<el-form-item label-width="201px" label="税务处罚: ">
									{{ swinfo.tax_penalty && swinfo.tax_penalty.length !== 0 ? '有' : '无' }} <span
										v-if="swinfo.tax_penalty && swinfo.tax_penalty.length !== 0" style="color:blue;cursor: pointer;"
										@click="swcfjl = true">详情
										></span>
								</el-form-item>
								<el-form-item label-width="201px" label="滞纳金: ">
									{{ swinfo.overdue_fine && swinfo.overdue_fine.length !== 0 ? '有' : '无' }} <span
										v-if="swinfo.overdue_fine && swinfo.overdue_fine.length !== 0" style="color:blue;cursor: pointer;"
										@click="zljjl = true">详情
										></span>
								</el-form-item>
								<el-form-item label-width="201px" label="近12个月纳税总额(元): ">
									{{ formatCurrency(swinfo.tax_ppev12mths_tax_paid) }}
								</el-form-item>
								<el-form-item label-width="201px" label="近12个月所得税已缴税额(元): ">
									{{ formatCurrency(swinfo.las_12m_cit_tax_paid) }}
								</el-form-item>
								<el-form-item label-width="201px" label="近24个月纳税总额(元): ">
									{{ formatCurrency(swinfo.tax_ppev24mths_tax_paid) }}
								</el-form-item>
								<el-form-item label-width="201px" label="近24个月所得税已缴税额(元): ">
									{{ formatCurrency(swinfo.las_24m_cit_tax_paid) }}
								</el-form-item>
								<div class="add-list" v-if="taxAccountingData
									.taxDeclarationIncomeTwoAmount ||
									taxAccountingData
										.taxDeclarationIncomeOneAmount ||
									taxAccountingData.taxDeclarationIncomeThisAmount
									">

									<el-form-item label-width="130px" v-if="taxAccountingData
										.taxDeclarationIncomeTwoAmount
										" label="纳税申报收入(元): ">
										{{
											taxAccountingData
												.taxDeclarationIncomeTwoYear
										}}年度
										{{
											formatCurrency(
												taxAccountingData
													.taxDeclarationIncomeTwoAmount
											)
										}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="taxAccountingData
												.taxDeclarationIncomeOneAmount
											" label="纳税申报收入(元): ">{{
			taxAccountingData
				.taxDeclarationIncomeOneYear
		}}年度

										{{
											formatCurrency(
												taxAccountingData
													.taxDeclarationIncomeOneAmount
											)
										}}
									</el-form-item>
									<el-form-item label-width="130px" v-if="taxAccountingData
												.taxDeclarationIncomeThisAmount
											" label="纳税申报收入(元): ">{{
			taxAccountingData
				.taxDeclarationIncomeThisYear
		}}年
										{{
											taxAccountingData
												.taxDeclarationIncomeThisYearMonthName
										}}
										{{
											formatCurrency(
												taxAccountingData
													.taxDeclarationIncomeThisAmount
											)
										}}
									</el-form-item>
								</div>


								<div class="add-list" v-if="taxAccountingData
											.totalTaxPaymentTwoAmount ||
										taxAccountingData
											.totalTaxPaymentOneAmount ||
										taxAccountingData
											.totalTaxPaymentThisAmount
										">
									<el-form-item label-width="130px" v-if="taxAccountingData
										.totalTaxPaymentTwoAmount
										" label="纳税总额(元): ">
										{{
											taxAccountingData
												.totalTaxPaymentTwoYear
										}}年度
										{{
											formatCurrency(
												taxAccountingData
													.totalTaxPaymentTwoAmount
											)
										}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="taxAccountingData
												.totalTaxPaymentOneAmount
											" label="纳税总额(元): ">{{
			taxAccountingData
				.totalTaxPaymentOneYear
		}}年度
										{{
											formatCurrency(
												taxAccountingData
													.totalTaxPaymentOneAmount
											)
										}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="taxAccountingData
												.totalTaxPaymentThisAmount
											" label="纳税总额(元): ">{{
			taxAccountingData
				.totalTaxPaymentThisYear
		}}年
										{{
											taxAccountingData
												.totalTaxPaymentThisYearMonthName
										}}
										{{
											formatCurrency(
												taxAccountingData
													.totalTaxPaymentThisAmount
											)
										}}
									</el-form-item>
								</div>

								<div class="add-list" v-if="taxAccountingData
											.taxDeclarationProfitOneAmount ||
										taxAccountingData
											.taxDeclarationProfitThisAmount
										">

									<el-form-item label-width="130px" v-if="taxAccountingData
										.taxDeclarationProfitOneAmount
										" label="纳税申报利润(元):">{{
		taxAccountingData
			.taxDeclarationProfitOneYear
	}}年度
										{{
											formatCurrency(
												taxAccountingData
													.taxDeclarationProfitOneAmount
											)
										}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="taxAccountingData
												.taxDeclarationProfitThisAmount
											" label="纳税申报利润(元):">{{
			taxAccountingData
				.taxDeclarationProfitThisYear
		}}年
										{{
											taxAccountingData
												.taxDeclarationProfitThisYearMonthName
										}}
										{{
											formatCurrency(
												taxAccountingData
													.taxDeclarationProfitThisAmount
											)
										}}
									</el-form-item>
								</div>

								<div class="add-list" v-if="taxAccountingData
											.taxAddedTwoAmount ||
										taxAccountingData
											.taxAddedOneAmount ||
										taxAccountingData
											.taxAddedThisAmount
										">
									<el-form-item label-width="130px" v-if="taxAccountingData
										.taxAddedTwoAmount
										" label="增值税(元): ">
										{{
											taxAccountingData
												.taxAddedTwoYear
										}}年度
										{{
											formatCurrency(
												taxAccountingData
													.taxAddedTwoAmount
											)
										}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="taxAccountingData
												.taxAddedOneAmount
											" label="增值税(元):">{{
			taxAccountingData
				.taxAddedOneYear
		}}年度
										{{
											formatCurrency(
												taxAccountingData
													.taxAddedOneAmount
											)
										}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="taxAccountingData
												.taxAddedThisAmount
											" label="增值税(元):">{{
			taxAccountingData
				.taxAddedThisYear
		}}年
										{{
											taxAccountingData
												.taxAddedThisYearMonthName
										}}
										{{
											formatCurrency(
												taxAccountingData
													.taxAddedThisAmount
											)
										}}
									</el-form-item>
								</div>

								<div class="add-list" v-if="taxAccountingData
											.taxIncomeTwoAmount ||
										taxAccountingData
											.taxIncomeOneAmount ||
										taxAccountingData
											.taxIncomeThisAmount
										">
									<el-form-item label-width="130px" v-if="taxAccountingData
										.taxIncomeTwoAmount
										" label="所得税(元): ">
										{{
											taxAccountingData
												.taxIncomeTwoYear
										}}年度
										{{
											formatCurrency(
												taxAccountingData
													.taxIncomeTwoAmount
											)
										}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="taxAccountingData
												.taxIncomeOneAmount
											" label="所得税(元):">{{
			taxAccountingData
				.taxIncomeOneYear
		}}年度
										{{
											formatCurrency(
												taxAccountingData
													.taxIncomeOneAmount
											)
										}}
									</el-form-item>

									<el-form-item label-width="130px" v-if="taxAccountingData
												.taxIncomeThisAmount
											" label="所得税(元):">{{
			taxAccountingData
				.taxIncomeThisYear
		}}年
										{{
											taxAccountingData
												.taxIncomeThisYearMonthName
										}}
										{{
											formatCurrency(
												taxAccountingData
													.taxIncomeThisAmount
											)
										}}
									</el-form-item>
								</div>
							</div>

							<p v-if="swinfo && swinfo.near_3years_tax_amount1" class="t2">
								<span class="t1-99">9.2.</span> <span>近三年纳税信息</span>
							</p>
							<el-table v-if="swinfo && swinfo.near_3years_tax_amount1" :summary-method="getSummaries1" show-summary
								:header-cell-style="{
									background: '#ecf5ff',
								}" :data="swinfo.near_3years_tax_amount1" border style="width: fit-content">

								<el-table-column width="50" prop="month" label="月份"></el-table-column>
								<el-table-column width="130" prop=""
									:label="swinfo.near_3years_tax_amount[swinfo.near_3years_tax_amount.length - 1].year + '(元)'">
									<template slot-scope="scope">
										{{ formatCurrency(scope.row.money_count[2]) }}
									</template>
								</el-table-column>
								<el-table-column width="130"
									:label="swinfo.near_3years_tax_amount[swinfo.near_3years_tax_amount.length - 1].year - 1 + '(元)'">
									<template slot-scope="scope">
										{{ formatCurrency(scope.row.money_count[1]) }}
									</template>
								</el-table-column>
								<el-table-column width="130"
									:label="swinfo.near_3years_tax_amount[swinfo.near_3years_tax_amount.length - 1].year - 2 + '(元)'">
									<template slot-scope="scope">
										{{ formatCurrency(scope.row.money_count[0]) }}
									</template>
								</el-table-column>
							</el-table>
							<p v-if="fpinfo && fpinfo.near_3years_invoice1" class="t2">
								<span class="t1-99">9.2.</span> <span>近三年开票信息</span>
							</p>
							<el-table v-if="fpinfo && fpinfo.near_3years_invoice1" :summary-method="getSummaries2" show-summary
								:header-cell-style="{
									background: '#ecf5ff',
								}" :data="fpinfo.near_3years_invoice1" border style="width: fit-content">

								<el-table-column width="50" prop="month" label="月份"></el-table-column>
								<el-table-column width="130" prop=""
									:label="fpinfo.near_3years_invoice[fpinfo.near_3years_invoice.length - 1].year + '(元)'">
									<template slot-scope="scope">
										{{ formatCurrency(scope.row.money_count[2]) }}
									</template>
								</el-table-column>
								<el-table-column width="130"
									:label="fpinfo.near_3years_invoice[fpinfo.near_3years_invoice.length - 1].year - 1 + '(元)'">
									<template slot-scope="scope">
										{{ formatCurrency(scope.row.money_count[1]) }}
									</template>
								</el-table-column>
								<el-table-column width="130"
									:label="fpinfo.near_3years_invoice[fpinfo.near_3years_invoice.length - 1].year - 2 + '(元)'">
									<template slot-scope="scope">
										{{ formatCurrency(scope.row.money_count[0]) }}
									</template>
								</el-table-column>
							</el-table>
							<p class="t2">
								<span class="t1-99">9.2.</span> <span>近12个月前十大开票客户（不含本月）</span>
							</p>
							<el-table :header-cell-style="{
								background: '#ecf5ff',
							}" :data="fpinfo.top10_customer_info" border show-summary :summary-method="getSummaries3"
								style="width: fit-content">
								<el-table-column type="index" :index="indexMethod" width="50" label="排名"></el-table-column>
								<el-table-column :width="flexColumnWidth(fpinfo.top10_customer_info, 'name', 260, 80)" prop="name"
									label="客户名称"></el-table-column>
								<el-table-column width="130" label="销售额(元)">
									<template slot-scope="scope">
										{{ formatCurrency(scope.row.amount) }}
									</template>
								</el-table-column>
								<el-table-column width="100" prop="amt_proportion" label="金额占比(%)"></el-table-column>
								<el-table-column width="100" label="是否关联方">
									<template slot-scope="scope">
										{{ scope.row.is_related_party ? '是' : '否' }}
									</template>
								</el-table-column>

							</el-table>
							<p class="t2">
								<span class="t1-99">9.2.</span> <span>近12个月前十大开票供应商（不含本月）</span>
							</p>
							<el-table :header-cell-style="{
								background: '#ecf5ff',
							}" :data="fpinfo.top10_supplier_info" border show-summary :summary-method="getSummaries3"
								style="width: fit-content">

								<el-table-column type="index" :index="indexMethod" width="50" label="排名"></el-table-column>

								<el-table-column :width="flexColumnWidth(fpinfo.top10_supplier_info, 'name', 260, 80)" prop="name"
									label="供应商名称	"></el-table-column>
								<el-table-column width="130" label="采购额(元)">
									<template slot-scope="scope">
										{{ formatCurrency(scope.row.amount) }}
									</template>
								</el-table-column>
								<el-table-column width="100" prop="amt_proportion" label="金额占比(%)"></el-table-column>
								<el-table-column width="100" label="是否关联方">
									<template slot-scope="scope">
										{{ scope.row.is_related_party ? '是' : '否' }}
									</template>
								</el-table-column>

							</el-table>

						</template>

						<p v-if="allData.bankStatement ||
							allData.bankFlowDownstreams.length ||
							allData.bankFlowUpstreams.length ||
							allData.flowIns.length ||
							allData.flowOuts.length ||
							allData.flowInAmounts.length ||
							allData.flowOutAmounts.length
							" class="t1">
							<span class="t1-10">10.</span> 银行流水分析表
						</p>
						<p class="t2" v-if="allData.bankStatement &&
							allData.bankStatement.startTime
							">
							<span class="t1-10">10.1.</span> 流水信息
						</p>
						<div class="running-info">
							<el-form-item v-if="allData.bankStatement &&
								allData.bankStatement.startTime
								" label="流水开始年月: ">
								{{
									allData.bankStatement &&
									allData.bankStatement.startTime
								}}
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									allData.bankStatement.endTime
									" label="流水截止年月: ">
								{{
									allData.bankStatement &&
									allData.bankStatement.endTime
								}}
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									allData.bankStatement.monthNum
									" label="流水区间月份数: ">
								{{
									allData.bankStatement &&
									allData.bankStatement.monthNum
								}}
								<span v-if="allData.bankStatement &&
										allData.bankStatement.monthNum
										">月</span>
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
								allData.bankStatement.bankName
								" label="流水对应银行: ">{{
		allData.bankStatement &&
		allData.bankStatement.bankName
	}}
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(allData.bankStatement.totalIncome)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">合并总流入:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'合计总流入：统计所有贷方金额的总和'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement.totalIncome
									)
								}}元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(allData.bankStatement.totalExpend)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">合计总流出:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'合计总流出：统计所有借方金额的总和'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement.totalExpend
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement.sameNameTransferIn
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">同名划入:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'同名划入：统计贷方交易对手是借款人本人的流水的贷方金额总和，交易对手与客户名称同名'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement.sameNameTransferIn
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.sameNameTransferOut
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">同名划出:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'同名划出：统计借方交易对手是借款人本人的流水的借方金额总和，交易对手与客户名称同名'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.sameNameTransferOut
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(allData.bankStatement.relatedFlowIn)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">关联或疑似关联流入:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'关联或疑似关联流入：统计贷方交易对手是借款人法人、股东、关联公司的流水的贷方金额总和，</br>交易对手与法定代表人同名、交易对手与关键人的姓名同名、交易对手与股东同名、交易对手与关联公司同名'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement.relatedFlowIn
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement.relatedFlowOut
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">关联或疑似关联流出:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'关联或疑似关联流出：统计借方交易对手是借款人法人、股东、关联公司的流水的的借方金额总和，</br>交易对手与法定代表人同名、交易对手与关键人的姓名同名、交易对手与股东同名、交易对手与关联公司同名'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement.relatedFlowOut
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.financialOrganizationsFlowIn
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">金融机构流入:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'金融机构流入：贷方交易对手名称含银行、保理、融资租赁、小贷、担保、金融等字样的流水的贷方金额总和'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.financialOrganizationsFlowIn
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.financialOrganizationsFlowOut
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">金融机构流出:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'金融机构流出：借方交易对手名称含银行、保理、融资租赁、小贷、担保、金融等字样的流水的借方金额总和'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.financialOrganizationsFlowOut
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.privateToPublicFlowIn
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">私转公流入:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'私转公流入：贷方交易对手是个人的流水统计，首先排除公司关键字，然后判断字符数介于4个字符到8个字符'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.privateToPublicFlowIn
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.publicToPrivateFlowOut
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">公转私流出:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'公转私流出：借方交易对手是个人的流水统计减去摘要显示含工资、报销的流水，首先排除公司关键字，然后判断字符数介于4个字符到8个字符'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.publicToPrivateFlowOut
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement.operationalFlowIn
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">经营性流入:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'经营性流入 = 合计总流入 - 同名划入 - 关联或疑似关联流入 - 金融机构流入 - 私转公流入 + 借款人法人、股东贷方金额总和'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement.operationalFlowIn
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement.operationalFlowOut
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">经营性流出:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'经营性流出 = 合计总流出 - 同名划出 - 关联或疑似关联流出 - 金融机构流出 - 公转私流出 + 借款人法人、股东借方金额总和'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement.operationalFlowOut
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.operationalFlowInRate
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">经营性流入占比:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'经营性流入占比 = 经营性流入/合计总流入'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.operationalFlowInRate
									)
								}}
								%
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.operationalFlowOutRate
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">经营性流出占比:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'经营性流出占比 = 经营性流出/合计总流出'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.operationalFlowOutRate
									)
								}}
								%
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.privateToPublicRate
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">私转公占比:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'私转公占比 = 私转公流入/合计总流入'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.privateToPublicRate
									)
								}}
								%
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.publicToPrivateRate
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">公转私占比:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'公转私占比 = 公转私流出/合计总流出'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.publicToPrivateRate
									)
								}}
								%
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.cycleAverageDailyBalance
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">统计周期日均余额:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'统计周期日均余额：统计周期日均余额。算法：统计每一天的余额/统计周期天数'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.cycleAverageDailyBalance
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.monthAverageDailyBalance
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">最近月日均余额:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'最近月日均余额，算法同周期日均余额，只是取数为最近一个月'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.monthAverageDailyBalance
									)
								}}
								元
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.cycleAverageDailyBalanceRate
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">统计周期日均余额/日均收入:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'统计周期日均余额/日均收入=日均余额/（上一年度主营业务收入/365）'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.cycleAverageDailyBalanceRate
									)
								}}
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.monthAverageDailyBalanceRate
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">最近月日均余额/日均收入:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'最近月日均余额/日均收入=日均余额/（上一年度主营业务收入/365）'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.monthAverageDailyBalanceRate
									)
								}}
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.cyclicalDailyCashRatio
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">统计周期日均现金比率(%):
									<el-tooltip effect="dark" placement="right">
										<span v-html="'统计周期日均余额(元)/最近月流动负债(取今年最近月数据，今年最近月没有数据，则取上年度12月数据)*100'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.cyclicalDailyCashRatio
									)
								}}
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement
											.dailyCashRatioInTheLatestMonth
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">最近月日均现金比率(%):
									<el-tooltip effect="dark" placement="right">
										<span v-html="'最近月日均余额(元)/最近月流动负债(取今年最近月数据，今年最近月没有数据，则取上年度12月数据)*100 '
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{
									formatCurrency(
										allData.bankStatement
											.dailyCashRatioInTheLatestMonth
									)
								}}
							</el-form-item>
							<el-form-item v-if="allData.bankStatement &&
									isEmpty(
										allData.bankStatement.loanRecordFlag
									)
									">
								<span style="
										font-weight: 400;
										position: relative;
										z-index: 1;
									" slot="label">是否有小额贷款公司往来记录:
									<el-tooltip effect="dark" placement="right">
										<span v-html="'是否有小额贷款公司往来记录:交易对手是否包含小额贷款、小贷的银行流水记录'
											" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								{{ allData.bankStatement.loanRecordFlag }}
							</el-form-item>
						</div>
						<p v-if="allData.bankFlowDownstreams.length &&
							!isShowRunning
							" class="t2">
							<span class="t1-10">10.2.</span>
							银行流水主要下游客户
						</p>
						<el-table v-if="allData.bankFlowDownstreams.length &&
							!isShowRunning
							" :header-cell-style="{ background: '#ecf5ff' }" :data="allData.bankFlowDownstreams" border style="width: 100%">
							<el-table-column type="index" width="50" label="序号">
							</el-table-column>
							<el-table-column prop="debitClientName" label="客户名称">
							</el-table-column>
							<el-table-column prop="amount" align="right" label="金额(万元/人民币)">
								<template slot-scope="scope">
									{{ formatCurrency(scope.row.amount) }}
								</template>
							</el-table-column>
							<el-table-column align="right" label="占比(%)">
								<template slot-scope="scope">
									{{
										Number(scope.row.ratio || 0).toFixed(2)
									}}
								</template>
							</el-table-column>
							<el-table-column prop="clientNatureName" label="客户性质">
							</el-table-column>
						</el-table>
						<p v-if="allData.bankFlowUpstreams.length &&
								!isShowRunning
								" class="t2">
							<span class="t1-10">10.3.</span>
							银行流水主要上游客户
						</p>
						<el-table v-if="allData.bankFlowUpstreams.length &&
							!isShowRunning
							" :header-cell-style="{ background: '#ecf5ff' }" :data="allData.bankFlowUpstreams" border style="width: 100%">
							<el-table-column type="index" width="50" label="序号">
							</el-table-column>
							<el-table-column prop="creditClientName" label="供应商名称">
							</el-table-column>
							<el-table-column label="金额(万元/人民币)" align="right">
								<template slot-scope="scope">
									{{ formatCurrency(scope.row.amount) }}
								</template>
							</el-table-column>
							<el-table-column align="right" label="占比(%)">
								<template slot-scope="scope">
									{{
										Number(scope.row.ratio || 0).toFixed(2)
									}}
								</template>
							</el-table-column>
							<el-table-column prop="supplierNatureName" label="供应商性质">
							</el-table-column>
						</el-table>
						<p class="t2" v-if="(allData.flowIns.length ||
									allData.flowOuts.length ||
									allData.flowInAmounts.length ||
									allData.flowOutAmounts.length) &&
								isShowRunning
								">
							<span class="t1-10">10.4.</span> 银行流水前十大
						</p>

						<el-tabs v-if="isShowRunning" v-model="activeName">
							<el-tab-pane v-if="allData.flowIns.length" label="流入客户" name="1">
								<div class="allw" style="width: 65%">
									<el-table :header-cell-style="{
										background: '#ecf5ff',
									}" :data="allData.flowIns" border style="width: 100%">
										<el-table-column prop="transactionOpponent" label="交易对手">
										</el-table-column>
										<el-table-column align="right" label="金额(元)">
											<template slot-scope="scope">
												{{
													formatCurrency(
														scope.row.amount
													)
												}}
											</template>
										</el-table-column>
										<el-table-column align="right" label="占比(%)">
											<template slot-scope="scope">
												{{
													Number(
														scope.row.proportion ||
														0
													).toFixed(2)
												}}
											</template>
										</el-table-column>
									</el-table>
								</div>
							</el-tab-pane>
							<el-tab-pane v-if="allData.flowOuts.length" label="流出客户" name="2">
								<div class="allw" style="width: 65%">
									<el-table :header-cell-style="{
										background: '#ecf5ff',
									}" :data="allData.flowOuts" border style="width: 100%">
										<el-table-column prop="transactionOpponent" label="交易对手">
										</el-table-column>
										<el-table-column align="right" label=" 金额(元)">
											<template slot-scope="scope">
												{{
													formatCurrency(
														scope.row.amount
													)
												}}
											</template>
										</el-table-column>
										<el-table-column align="right" label="占比(%)">
											<template slot-scope="scope">
												{{
													Number(
														scope.row.proportion ||
														0
													).toFixed(2)
												}}
											</template>
										</el-table-column>
									</el-table>
								</div>
							</el-tab-pane>
							<el-tab-pane v-if="allData.flowInAmounts.length" label="大额流入" name="3">
								<div class="allw" style="width: 65%">
									<el-table :header-cell-style="{
										background: '#ecf5ff',
									}" :data="allData.flowInAmounts" border style="width: 100%">
										<el-table-column width="100" prop="date" label="日期">
										</el-table-column>
										<el-table-column min-width="100" prop="transactionOpponent" label="交易对手">
										</el-table-column>
										<el-table-column width="120" align="right" label="金额(元)">
											<template slot-scope="scope">
												{{
													formatCurrency(
														scope.row.amount
													)
												}}
											</template>
										</el-table-column>

										<el-table-column min-width="100" prop="summary" label="摘要">
										</el-table-column>
									</el-table>
								</div>
							</el-tab-pane>
							<el-tab-pane v-if="allData.flowOutAmounts.length" label="大额流出" name="4">
								<div class="allw" style="width: 65%">
									<el-table :header-cell-style="{
										background: '#ecf5ff',
									}" :data="allData.flowOutAmounts" border fit>
										<el-table-column width="100" prop="date" label="日期">
										</el-table-column>
										<el-table-column min-width="100" prop="transactionOpponent" label="交易对手">
										</el-table-column>

										<el-table-column align="right" width="120" label="金额(元)">
											<template slot-scope="scope">
												{{
													formatCurrency(
														scope.row.amount
													)
												}}
											</template>
										</el-table-column>
										<el-table-column min-width="100" prop="summary" label="摘要">
										</el-table-column>
									</el-table>
								</div>
							</el-tab-pane>
						</el-tabs>

						<p class="t1" v-if="allData.capitalizesList.length">
							<span class="t1-7">7.</span>
							企业及实控人当前融资情况表
							{{ allMoney }}
						</p>
						<el-table v-if="allData.capitalizesList.length" :header-cell-style="{ background: '#ecf5ff' }"
							:data="allData.capitalizesList" border style="width: 100%">
							<el-table-column prop="borrowerName" label="借款人名称">
							</el-table-column>
							<el-table-column label="融资机构">
								<template slot-scope="scope">
									{{
										scope.row.capitalizeOrg ||
										scope.row.capitalizeOrgName
									}}
								</template>
							</el-table-column>
							<el-table-column align="right" label="融资金额(万元)">
								<template slot-scope="scope">
									{{
										formatCurrency(
											scope.row.capitalizeAmount
										)
									}}
								</template>
							</el-table-column>
							<el-table-column prop="capitalizeDeadline" label="融资期限(月)">
							</el-table-column>
							<el-table-column align="right" label="贷款余额(万元)">
								<template slot-scope="scope">
									{{ formatCurrency(scope.row.loanBalance) }}
								</template>
							</el-table-column>
							<el-table-column align="right" label="利率(%)">
								<template slot-scope="scope">
									{{
										scope.row.interestRate
										? Number(
											scope.row.interestRate
										).toFixed(2)
										: ''
									}}
								</template>
							</el-table-column>
							<el-table-column prop="capitalizeTypeName" label="融资类型">
							</el-table-column>
							<el-table-column prop="guaranteeModelName" label="担保方式">
							</el-table-column>
							<el-table-column style="width: 100%" v-if="privacyType == 2" label="附件">
								<template slot-scope="scope">
									<div v-for="(urlitem, urlindex) in scope.row
										.guaranteeAttachment" :key="urlindex" style="color: blue;cursor: pointer;" @click="handelOnChange(urlitem)">
										{{ urlitem.name }}</div>
								</template>
							</el-table-column>
						</el-table>
						<el-form-item style="width: 100%" v-if="allData.clientBase &&
							allData.clientBase.capitalizeAttachment &&
							privacyType == 2
							" label="附件:">
							<el-upload style="line-height: 0; margin-left: 50px" disabled name="files"
								:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" :on-preview="handelOnChange" multiple
								:file-list="allData.clientBase.capitalizeAttachment
									">
							</el-upload>
						</el-form-item>
					</el-form>
				</div>
				<el-empty v-else slot="empty" description="暂无客户详细信息"></el-empty>
				<el-card v-if="allData.riskScreeningInformationList &&
					allData.riskScreeningInformationList.length
					" class="box-card">
					<p style="
							font-size: 20px;
							color: #3e31ff;
							font-weight: 800;
							margin: 10px 0;
						">
						风险筛查
					</p>

					<el-table :header-cell-style="{
						background: '#ecf5ff',
					}" :data="allData.riskScreeningInformationList" :span-method="objectSpanMethod1" border style="width: 100%">
						<el-table-column prop="tryingToStudy" label="排查项">
						</el-table-column>
						<el-table-column prop="subjects" label="科目">
						</el-table-column>
						<el-table-column label="详情">
							<template slot-scope="scope">
								<span>{{ scope.row.content.replace('，点击查看', '').replace('，点击查看详情', '').replace('，详情', '') }}</span>


							</template>
						</el-table-column>
						<el-table-column prop="levelName" label="风险等级">
						</el-table-column>
					</el-table>
				</el-card>
				<el-card v-if="allData.competitivenessScreeningInformationList &&
					allData.competitivenessScreeningInformationList.length
					" class="box-card">
					<p style="
							font-size: 20px;
							color: #3e31ff;
							font-weight: 800;
							margin: 10px 0;
						">
						竞争力筛查
					</p>
					<el-table :header-cell-style="{
						background: '#ecf5ff',
					}" :span-method="objectSpanMethod2" :data="allData.competitivenessScreeningInformationList" border
						style="width: 100%">
						<el-table-column prop="tryingToStudy" label="排查项">
						</el-table-column>
						<el-table-column prop="subjects" label="科目">
						</el-table-column>
						<el-table-column prop="content" label="详情">
						</el-table-column>
						<el-table-column prop="levelName" label="竞争力评级">
						</el-table-column>
					</el-table>
				</el-card>
				<div class="demo-drawer__footer">
					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import {
	readCooperationFeedback,
	cooperationFeedbackSave,
	financialProductList,
	itemByType,
	clientBankFlowList,
	getVfqClinetData,
	getVfqClinetDatas
} from '@/api/index.js';
export default {
	name: 'mobileInfoDrawer',
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
		},
		allData: {
			type: Object,
			required: true,
		},
		paramsId: {
			type: Object,
			required: true,
		},
		PresidentCooperateWillingness: {
			type: Array,
			default: () => [],
		},
		privacyType: {
			type: Number,
			required: true,
		},
	},
	data() {
		const Financing = (rule, value, callback) => {
			const Financing_max = this.$refs.Financing_max.value;
			if (value && Financing_max && value <= Financing_max) {
				callback();
			} else {
				if (!value) {
					callback(new Error('请输入最小值'));
				}
				if (!Financing_max) {
					callback(new Error('请输入最大值'));
				}
				if (value > Financing_max) {
					callback(new Error('最小值不能大于最大值'));
				}
			}
		};
		const InterestRate = (rule, value, callback) => {
			const interestRate_max = this.$refs.interestRate_max.value;
			if (value && interestRate_max && value <= interestRate_max) {
				callback();
			} else {
				if (!value) {
					callback(new Error('请输入最小值'));
				}
				if (!interestRate_max) {
					callback(new Error('请输入最大值'));
				}
				if (value > interestRate_max) {
					callback(new Error('最小值不能大于最大值'));
				}
			}
		};
		return {
			zljjl: false,
			swcfjl: false,
			qsjl: false,
			taxAccountingData: {},
			swinfo: {},
			fpinfo: {},
			sfinfo: {},
			gsinfo: {},
			sfsfinfo: {},
			sfinfoobj: {},
			dtinfo: {},
			isswbg: false,
			mergeObj: {}, // 用来记录需要合并行的下标
			mergeObj1: {}, // 用来记录需要合并行的下标
			mergeArr: ['tryingToStudy', 'subjects'], // 表格中的列名
			activeName: '1',
			isData: true,

			isShowRunning: '',
			rules: {
				minFinancing: [
					{
						required: true,
						validator: Financing,
						trigger: ['blur', 'change'],
					},
				],
				interestRateMin: [
					{
						required: true,
						validator: InterestRate,
						trigger: ['blur', 'change'],
					},
				],
			},
			ruleForm: {
				dealStatus: '',
				cooperateWillingness: '',
				applicableProducts: '',
				minFinancing: '',
				maxFinancing: '',
				interestRateMin: '',
				interestRateMax: '',
				loanDeadline: '',
				repaymentMethod: '',
				guaranteeConditions: '',
				abandonReason: '',
				creditAccess: '',
				clientPresidentRelatedId: 0,
			},
			tableData: [
				{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-08',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-06',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-08',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-06',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
				{
					date: '2016-05-07',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄',
				},
			],
			headers: [
				{
					prop: 'title',
					label: '科目',
				},
				{
					prop: '',
					label: '流动资产：',
				},
				{
					prop: 'monetaryCapitalClosingBalance',
					label: '货币资金(元)',
				},
				{
					prop: 'transactionalFinancialAssetsClosingBalance',
					label: '交易性金融资产(元)',
				},
				{
					prop: 'billReceivableClosingBalance',
					label: '应收票据(元)',
				},
				{
					prop: 'accountsReceivableClosingBalance',
					label: '应收账款(元)',
				},
				{
					prop: 'advancePaymentClosingBalance',
					label: '预付款项(元)',
				},
				{
					prop: 'interestReceivableClosingBalance',
					label: '应收利息(元)',
				},
				{
					prop: 'dividendsReceivableClosingBalance',
					label: '应收股利(元)',
				},
				{
					prop: 'otherReceivableClosingBalance',
					label: '其他应收款(元)',
				},
				{
					prop: 'inventoryClosingBalance',
					label: '存货(元)',
				},
				{
					prop: 'nonCurrentAssetsClosingBalance',
					label: '一年内到期的非流动资产(元)',
				},
				{
					prop: 'otherCurrentAssetsClosingBalance',
					label: '其他流动资产(元)',
				},
				{
					prop: 'currentAssetsTotalClosingBalance',
					label: '流动资产合计(元)',
				},
				{
					prop: '',
					label: '非流动资产：',
				},
				{
					prop: 'saleableFinancialAssetsClosingBalance',
					label: '可供出售金融资产(元)',
				},
				{
					prop: 'matureInvestmentClosingBalance',
					label: '持有至到期投资(元)',
				},
				{
					prop: 'longTermReceivablesClosingBalance',
					label: '长期应收款(元)',
				},
				{
					prop: 'longTermEquityInvestmentClosingBalance',
					label: '长期股权投资(元)',
				},
				{
					prop: 'investmentRealEstateClosingBalance',
					label: '投资性房地产(元)',
				},
				{
					prop: 'capitalAssetsClosingBalance',
					label: '固定资产(元)',
				},
				{
					prop: 'projectProgressClosingBalance',
					label: '在建工程(元)',
				},
				{
					prop: 'projectMaterialClosingBalance',
					label: '工程物资(元)',
				},
				{
					prop: 'capitalAssetsDisposalClosingBalance',
					label: '固定资产清理(元)',
				},
				{
					prop: 'productiveBiologicalAssetsClosingBalance',
					label: '生产性生物资产(元)',
				},
				{
					prop: 'gasAssetsClosingBalance',
					label: '油气资产(元)',
				},
				{
					prop: 'intangibleAssetsClosingBalance',
					label: '无形资产(元)',
				},
				{
					prop: 'developmentExpenditureClosingBalance',
					label: '开发支出(元)',
				},
				{
					prop: 'goodwillClosingBalance',
					label: '商誉(元)',
				},
				{
					prop: 'longTermDeferredExpensesClosingBalance',
					label: '长期待摊费用(元)',
				},
				{
					prop: 'deferredTaxAssetsClosingBalance',
					label: '递延所得税资产(元)',
				},
				{
					prop: 'otherNonCurrentAssetsClosingBalance',
					label: '其他非流动资产(元)',
				},
				{
					prop: 'nonCurrentAssetsTotalClosingBalance',
					label: '非流动资产合计(元)',
				},
				{
					prop: 'assetsTotalClosingBalance',
					label: '资产总计(元)',
				},
				{
					prop: '',
					label: '流动负债：',
				},
				{
					prop: 'shortTermBorrowingsClosingBalance',
					label: '短期借款(元)',
				},
				{
					prop: 'tradingFinancialLiabilitiesClosingBalance',
					label: '交易性金融负债(元)',
				},
				{
					prop: 'billPayableClosingBalance',
					label: '应付票据(元)',
				},
				{
					prop: 'accountsPayableClosingBalance',
					label: '应付账款(元)',
				},
				{
					prop: 'advanceReceiveClosingBalance',
					label: '预收款项(元)',
				},
				{
					prop: 'payrollPayableClosingBalance',
					label: '应付职工薪酬(元)',
				},
				{
					prop: 'taxesPayableClosingBalance',
					label: '应交税费(元)',
				},
				{
					prop: 'interestPayableClosingBalance',
					label: '应付利息(元)',
				},
				{
					prop: 'dividendsPayableClosingBalance',
					label: '应付股利(元)',
				},
				{
					prop: 'otherPayablesClosingBalance',
					label: '其他应付款(元)',
				},
				{
					prop: 'nonCurrentLiabilitiesClosingBalance',
					label: '一年内到期的非流动负债(元)',
				},
				{
					prop: 'otherCurrentLiabilitiesClosingBalance',
					label: '其他流动负债(元)',
				},
				{
					prop: 'currentLiabilitiesTotalClosingBalance',
					label: '流动负债合计(元)',
				},
				{
					prop: '',
					label: '非流动负债：',
				},
				{
					prop: 'longTermBorrowingsClosingBalance',
					label: '长期借款(元)',
				},
				{
					prop: 'bondsPayableClosingBalance',
					label: '应付债券(元)',
				},
				{
					prop: 'longTermPayablesClosingBalance',
					label: '长期应付款(元)',
				},
				{
					prop: 'specialPayablesClosingBalance',
					label: '专项应付款(元)',
				},
				{
					prop: 'estimatedLiabilitiesClosingBalance',
					label: '预计负债(元)',
				},
				{
					prop: 'deferredTaxLiabilityClosingBalance',
					label: '递延所得税负债(元)',
				},
				{
					prop: 'otherNonCurrentLiabilitiesClosingBalance',
					label: '其他非流动负债(元)',
				},
				{
					prop: 'nonCurrentLiabilitiesTotalClosingBalance',
					label: '非流动负债合计(元)',
				},
				{
					prop: 'liabilitiesTotalClosingBalance',
					label: '负债合计(元)',
				},
				{
					prop: 'subscribedCapitalClosingBalance',
					label: '实收资本(元)',
				},
				{
					prop: 'capitalReserveClosingBalance',
					label: '资本公积(元)',
				},
				{
					prop: 'lessTreasuryStockClosingBalance',
					label: '减：库存股(元)',
				},
				{
					prop: 'specialReserveClosingBalance',
					label: '专项储备(元)',
				},
				{
					prop: 'surplusReserveClosingBalance',
					label: '盈余公积(元)',
				},
				{
					prop: 'undistributedProfitsClosingBalance',
					label: '未分配利润(元)',
				},
				{
					prop: 'totalOwnerEquityClosingBalance',
					label: '所有者权益合计(元)',
				},
				{
					prop: 'totalLiabilitiesAndOwnerEquityClosingBalance',
					label: '负债和所有者权益总计(元)	',
				},
			],
			income: [
				{
					prop: 'title',
					label: '科目',
				},
				{
					prop: 'businessIncomeMonthAmount',
					label: '一、营业收入(元)',
				},
				{
					prop: 'operatingCostsMonthAmount',
					label: '减：营业成本(元)',
				},
				{
					prop: 'salesTaxMonthAmount',
					label: '营业税金及附加(元)',
				},
				{
					prop: 'sellingCostsMonthAmount',
					label: '销售费用(元)',
				},
				{
					prop: 'administrativeExpensesMonthAmount',
					label: '管理费用(元)',
				},
				{
					prop: 'financialExpensesMonthAmount',
					label: '财务费用(元)',
				},
				{
					prop: 'assetsImpairmentLossMonthAmount',
					label: '资产减值损失(元)',
				},
				{
					prop: 'variableIncomeMonthAmount',
					label: '加：公允价值变动收益（损失以“-”号填列）(元)',
				},
				{
					prop: 'investmentIncomeMonthAmount',
					label: '投资收益（损失以“-”号填列）(元)',
				},
				{
					prop: 'cooperativeEnterpriseInvestmentIncomeMonthAmount',
					label: '其中：对联营企业和合营企业的投资收益(元)',
				},
				{
					prop: 'operatingProfitMonthAmount',
					label: '二、营业利润（亏损以“－”号填列）(元)',
				},
				{
					prop: 'nonOperatingIncomeMonthAmount',
					label: '加：营业外收入(元)',
				},
				{
					prop: 'nonOperatingExpensesMonthAmount',
					label: '减：营业外支出(元)',
				},
				{
					prop: 'nonCurrentAssetsDisposalLossMonthAmount',
					label: '其中：非流动资产处置损失(元)',
				},
				{
					prop: 'totalProfitMonthAmount',
					label: '三、利润总额（亏损总额以“－”号填列）(元)',
				},
				{
					prop: 'incomeTaxExpenseMonthAmount',
					label: '减：所得税费用(元)',
				},
				{
					prop: 'netProfitMonthAmount',
					label: '四、净利润(元)',
				},
				{
					prop: 'earningsPerShareMonthAmount',
					label: '五、每股收益(元)',
				},
				{
					prop: 'basicEarningsPerShareMonthAmount',
					label: '（一）基本每股收益(元)',
				},
				{
					prop: 'dilutedEarningsPerShareMonthAmount',
					label: '（二）稀释每股收益(元)',
				},
			],
			indicator: [
				{
					prop: 'title',
					label: '财务指标',
				},

				{
					prop: 'xxx',
					label: '一、偿债能力指标',
				},
				{
					prop: 'ratioCurrent',
					label: '流动比率(%)',
				},

				{
					prop: 'ratioQuick',
					label: '速动比率(%) ',
				},
				{
					prop: 'liabRateAssets',
					label: '资产负债率(%) ',
				},
				{
					prop: 'liabRateBank',
					label: '银行负债率(%)',
				},
				{
					prop: 'xxxx',
					label: '二、营运能力指标 ',
				},
				{
					prop: 'receivablesTurnover',
					label: '应收账款周转天数',
				},
				{
					prop: 'inventoryTurnover',
					label: '存货周转天数',
				},
				{
					prop: 'xxxx',
					label: '三、盈利能力指标',
				},
				{
					prop: 'saleGrossMargin',
					label: '销售毛利率(%)',
				},
				{
					prop: 'saleInterestRate',
					label: '销售净利率(%)',
				},
				{
					prop: 'growthRateRevenue',
					label: '销售收入增长率(%) ',
				},
				{
					prop: 'growthRateProfit',
					label: '净利润增长率(%)',
				},
				{
					prop: 'cashRatio',
					label: '现金比率(%)',
				},
			],
			restaurants: [],
			RepaymentMethod: [],
		};
	},
	methods: {
		flexColumnWidth(arr1, name, maxWidth, titeleWidth) {
			// name为该列的字段名(传字符串);arr1为该表格的数据源(传变量);
			let columnContent = "";
			if (!arr1 || !arr1.length || arr1.length === 0 || arr1 === undefined) {
				return titeleWidth + "px"; // 给个默认的
			}

			// 获取该列中最长的数据(内容)
			let index = 0;
			for (let i = 0; i < arr1.length; i++) {
				if (arr1[i][name] === null) {
					return;
				}
				if (arr1[i][name] === undefined) {
					//字段没有时默认数据为空
					arr1[i][name] = "";
				}
				const now_temp = arr1[i][name] + "";
				const max_temp = arr1[index][name] + "";
				if (now_temp.length > max_temp.length) {
					index = i;
				}
			}
			columnContent = arr1[index][name];
			// console.log('该列数据[i]:', columnContent)
			// 以下分配的单位长度可根据实际需求进行调整
			let flexWidth = 0;
			for (const char of columnContent) {
				if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
					// 如果是英文字符，为字符分配8个单位宽度
					flexWidth += 8;
				} else if (char >= "\u4e00" && char <= "\u9fa5") {
					// 如果是中文字符，为字符分配15个单位宽度
					flexWidth += 16;
				} else {
					// 其他种类字符，为字符分配8个单位宽度
					flexWidth += 10;
				}
			}
			// if (flexWidth < 80) {
			// 	// 设置最小宽度
			// 	flexWidth = 80;
			// }
			// if (flexWidth > 250) {
			//   // 设置最大宽度
			//   flexWidth = 250
			// }
			//留边距
			flexWidth += 20;
			if (flexWidth == 20) {
				flexWidth = titeleWidth
			}
			if (flexWidth > maxWidth) {
				flexWidth = maxWidth
			}
			if (flexWidth < titeleWidth) {
				flexWidth = titeleWidth
			}
			return flexWidth;
		},
		indexMethod(index) {
			return index + 1;
		},
		getSummaries1(param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '合计';
					return;
				}
				if (index === 1) {
					sums[index] = this.formatCurrency(this.swinfo.near_3years_tax_amountresult[this.swinfo.near_3years_tax_amount[this.swinfo.near_3years_tax_amount.length
						- 1].year] / 100)
					return;
				}
				if (index === 2) {
					sums[index] = this.formatCurrency(this.swinfo.near_3years_tax_amountresult[this.swinfo.near_3years_tax_amount[this.swinfo.near_3years_tax_amount.length
						- 1].year - 1] / 100)
					return;
				}
				if (index === 3) {
					sums[index] = this.formatCurrency(this.swinfo.near_3years_tax_amountresult[this.swinfo.near_3years_tax_amount[this.swinfo.near_3years_tax_amount.length
						- 1].year - 2] / 100)
					return;
				}
			});

			return sums;
		},
		getSummaries2(param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '合计';
					return;
				}
				if (index === 1) {
					sums[index] = this.formatCurrency(this.fpinfo.near_3years_invoiceresult[this.fpinfo.near_3years_invoice[this.fpinfo.near_3years_invoice.length -
						1].year]['money_count'] / 100)
					return;
				}
				if (index === 2) {
					sums[index] = this.formatCurrency(this.fpinfo.near_3years_invoiceresult[this.fpinfo.near_3years_invoice[this.fpinfo.near_3years_invoice.length -
						1].year - 1]['money_count'] / 100)
					return;
				}
				if (index === 3) {
					sums[index] = this.formatCurrency(this.fpinfo.near_3years_invoiceresult[this.fpinfo.near_3years_invoice[this.fpinfo.near_3years_invoice.length -
						1].year - 2]['money_count'] / 100)
					return;
				}
			});

			return sums;
		},
		getSummaries3(param) {
			const { columns, data } = param;
			console.log(data)

			const sums = [];
			columns.forEach((column, index) => {

				if (index === 0) {
					sums[index] = '合计';
					return;
				}

				if (index === 2) {
					let count = 0
					data.forEach(item => {
						count += (item.amount * 1000)
					})
					sums[index] = this.formatCurrency((count / 1000).toFixed(2));
					return;
				}
				if (index === 3) {
					let count = 0
					data.forEach(item => {
						count += (item.amt_proportion * 1000)
					})
					sums[index] = (count / 1000).toFixed(2);
					return;
				}
			});

			return sums;
		},
		async getVfqClinetDatafn(companyCode) {
			const res = await getVfqClinetDatas({ companyCode })
			res.rtnDataObj.forEach(el => {
				if (el.interfaceType == 6) {
					let repData = JSON.parse(el.repData);
					console.log(repData, '------------------------')
					if (repData.code == 200) {
						if (repData.data.near_3years_tax_amount && repData.data.near_3years_tax_amount.length) {
							let yearMinusThree = repData.data.near_3years_tax_amount[repData.data.near_3years_tax_amount.length - 1].year - 3;

							repData.data.near_3years_tax_amount.forEach((item, index) => {
								if (item.year <= yearMinusThree) {
									delete repData.data.near_3years_tax_amount[index]
								}
							})

							repData.data.near_3years_tax_amount1 = repData.data.near_3years_tax_amount.reduce((acc, curr) => {
								const existingObj = acc.find(obj => obj.month == curr.month);
								if (existingObj) {
									existingObj.money_count.push(curr.money_count);
								} else {
									acc.push({
										month: curr.month,
										money_count: [curr.money_count]
									});
								}
								return acc;
							}, []);


							repData.data.near_3years_tax_amountresult = repData.data.near_3years_tax_amount.reduce((acc, curr) => {
								const year = curr.year;
								const moneyCount = curr.money_count;
								if (year in acc) {
									acc[year] += moneyCount * 100;
								} else {
									acc[year] = moneyCount * 100;
								}
								return acc;
							}, {});

						}

						if (repData.data.overdue_fine.length) {
							repData.data.overdue_fine.sort(function (a, b) {
								var date1 = new Date(a.tax_time.split('--')[0]);
								var date2 = new Date(b.tax_time.split('--')[0]);
								return date2 - date1;
							});
						}


						this.swinfo = repData.data
						this.taxAccountingData = el.taxAccountingData ? JSON.parse(el.taxAccountingData) : ''
						console.log(this.taxAccountingData);
					}
				}
				if (el.interfaceType == 7) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {



						if (repData.data.near_3years_invoice && repData.data.near_3years_invoice.length) {
							repData.data.near_3years_invoice1 = repData.data.near_3years_invoice.reduce((acc, curr) => {
								const existingObj = acc.find(obj => obj.month == curr.month);
								if (existingObj) {
									existingObj.money_count.push(curr.money_count);
									existingObj.invldfp_amt.push(curr.invldfp_amt);
									existingObj.quantity_count.push(curr.quantity_count);
									existingObj.hcfp_amt.push(curr.hcfp_amt);
								} else {
									acc.push({
										month: curr.month,
										money_count: [curr.money_count],
										hcfp_amt: [curr.hcfp_amt],
										quantity_count: [curr.quantity_count],
										invldfp_amt: [curr.invldfp_amt]
									});
								}
								return acc;
							}, []);

							repData.data.near_3years_invoiceresult = repData.data.near_3years_invoice.reduce((acc, curr) => {
								const year = curr.year;
								const moneyCount = curr.money_count;
								const quantityCount = curr.quantity_count;
								const invldfpAmt = curr.invldfp_amt;
								const hcfpAmt = curr.hcfp_amt;

								if (year in acc) {
									acc[year].money_count += moneyCount * 100;
									acc[year].quantity_count += quantityCount * 100;
									acc[year].invldfp_amt += invldfpAmt * 100;
									acc[year].hcfp_amt += hcfpAmt * 100;
								} else {
									acc[year] = {
										money_count: moneyCount * 100,
										quantity_count: quantityCount * 100,
										invldfp_amt: invldfpAmt * 100,
										hcfp_amt: hcfpAmt * 100
									};
								}

								return acc;
							}, {});



						}




						this.fpinfo = repData.data

					}

				}
				if (el.interfaceType == 9) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {
						this.sfinfo = repData.data

					}

				}
				if (el.interfaceType == 20) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {
						this.gsinfo = repData.data

					}
				}
				if (el.interfaceType == 40) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {
						this.dtinfo = repData.data

					}
				}
				if (el.interfaceType == 11) {
					let repData = JSON.parse(el.repData);
					if (repData.code == 200) {
						this.sfsfinfo = repData.data

					}
				}
			});


			if (this.allData.financeRequirementList.length >= 1) {
				this.$nextTick(() => {
					if (
						!Array.from(document.querySelectorAll('.t1')).length
					) {
						this.isData = false;
					}
					Array.from(document.querySelectorAll('.t1')).forEach(
						(item, index) => {
							Array.from(
								document.querySelectorAll(
									`.${item.querySelector('span').className
									}`
								)
							).forEach((items, indexs) => {
								if (indexs) {
									items.innerHTML = `${index + 1
										}.${indexs}.`;
								} else {
									items.innerHTML = `${index + 1}.`;
								}
							});
							Array.from(
								document.querySelectorAll(
									`.${item.querySelector('span').className
									}-1-1`
								)
							).forEach((items, indexs) => {
								items.innerHTML = `${index + 1}.1.${indexs + 1
									}.`;
							});
						}
					);
				});
			} else {
				this.$nextTick(() => {
					if (
						!Array.from(document.querySelectorAll('.t1')).length
					) {
						this.isData = false;
					}
					Array.from(document.querySelectorAll('.t1')).forEach(
						(item, index) => {
							Array.from(
								document.querySelectorAll(
									`.${item.querySelector('span').className
									}`
								)
							).forEach((items, indexs) => {
								if (indexs) {
									items.innerHTML = `${index + 1
										}.${indexs}.`;
								} else {
									items.innerHTML = `${index + 1}.`;
								}
							});
							Array.from(
								document.querySelectorAll(
									`.${item.querySelector('span').className
									}-1-1`
								)
							).forEach((items, indexs) => {
								items.innerHTML = `${index + 1}.1.${indexs + 1
									}.`;
							});
						}
					);
				});
			}
		},
		async getgetVfqClinetData(creditCode) {
			const res = await getVfqClinetData({
				companyCode: creditCode
			})
			if (res.returncode == 0) {
				this.isswbg = res.rtnDataObj
				if (res.rtnDataObj) {
					this.getVfqClinetDatafn(creditCode)
				} else {
					if (this.allData.financeRequirementList.length >= 1) {
						this.$nextTick(() => {
							if (
								!Array.from(document.querySelectorAll('.t1')).length
							) {
								this.isData = false;
							}
							Array.from(document.querySelectorAll('.t1')).forEach(
								(item, index) => {
									Array.from(
										document.querySelectorAll(
											`.${item.querySelector('span').className
											}`
										)
									).forEach((items, indexs) => {
										if (indexs) {
											items.innerHTML = `${index + 1
												}.${indexs}.`;
										} else {
											items.innerHTML = `${index + 1}.`;
										}
									});
									Array.from(
										document.querySelectorAll(
											`.${item.querySelector('span').className
											}-1-1`
										)
									).forEach((items, indexs) => {
										items.innerHTML = `${index + 1}.1.${indexs + 1
											}.`;
									});
								}
							);
						});
					} else {
						this.$nextTick(() => {
							if (
								!Array.from(document.querySelectorAll('.t1')).length
							) {
								this.isData = false;
							}
							Array.from(document.querySelectorAll('.t1')).forEach(
								(item, index) => {
									Array.from(
										document.querySelectorAll(
											`.${item.querySelector('span').className
											}`
										)
									).forEach((items, indexs) => {
										if (indexs) {
											items.innerHTML = `${index + 1
												}.${indexs}.`;
										} else {
											items.innerHTML = `${index + 1}.`;
										}
									});
									Array.from(
										document.querySelectorAll(
											`.${item.querySelector('span').className
											}-1-1`
										)
									).forEach((items, indexs) => {
										items.innerHTML = `${index + 1}.1.${indexs + 1
											}.`;
									});
								}
							);
						});
					}
				}
			}
		},
		getSpanArr(data) {
			this.mergeArr.forEach((key, index1) => {
				let count = 0; // 用来记录需要合并行的起始位置
				this.mergeObj[key] = []; // 记录每一列的合并信息
				data.forEach((item, index) => {
					// index == 0表示数据为第一行，直接 push 一个 1
					if (index === 0) {
						this.mergeObj[key].push(1);
					} else {
						// 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
						if (item[key] === data[index - 1][key]) {
							this.mergeObj[key][count] += 1;
							this.mergeObj[key].push(0);
						} else {
							// 如果当前行和上一行其值不相等
							count = index; // 记录当前位置
							this.mergeObj[key].push(1); // 重新push 一个 1
						}
					}
				});
			});
		},
		getSpanArr1(data) {
			this.mergeArr.forEach((key, index1) => {
				let count = 0; // 用来记录需要合并行的起始位置
				this.mergeObj1[key] = []; // 记录每一列的合并信息
				data.forEach((item, index) => {
					// index == 0表示数据为第一行，直接 push 一个 1
					if (index === 0) {
						this.mergeObj1[key].push(1);
					} else {
						// 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
						if (item[key] === data[index - 1][key]) {
							this.mergeObj1[key][count] += 1;
							this.mergeObj1[key].push(0);
						} else {
							// 如果当前行和上一行其值不相等
							count = index; // 记录当前位置
							this.mergeObj1[key].push(1); // 重新push 一个 1
						}
					}
				});
			});
		},
		// 默认接受四个值 { 当前行的值, 当前列的值, 行的下标, 列的下标 }
		objectSpanMethod1({ row, column, rowIndex, columnIndex }) {
			// 判断列的属性
			if (this.mergeArr.indexOf(column.property) !== -1) {
				// 判断其值是不是为0
				if (this.mergeObj[column.property][rowIndex]) {
					return [this.mergeObj[column.property][rowIndex], 1];
				} else {
					// 如果为0则为需要合并的行
					return [0, 0];
				}
			}
		},
		objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
			// 判断列的属性
			if (this.mergeArr.indexOf(column.property) !== -1) {
				// 判断其值是不是为0
				if (this.mergeObj1[column.property][rowIndex]) {
					return [this.mergeObj1[column.property][rowIndex], 1];
				} else {
					// 如果为0则为需要合并的行
					return [0, 0];
				}
			}
		},
		isEmpty(str) {
			if (str === null || typeof str === 'undefined' || str === '') {
				return false;
			}
			return true;
		},
		handelOnChange(res) {
			if (res.filePath) {
				res.url = res.filePath
			}
			if (res.url.includes('png') || res.url.includes('jpg') || res.url.includes('bmp') || res.url.includes('jpeg') || res.url.includes('xlsx') || res.url.includes('docx') || res.url.includes('pdf')) {
				let routeData = this.$router.resolve({ path: '/public', query: { url: res.url } });

				window.open(routeData.href, '_blank');
				return;
			}

			location.href =
				this.$baseUrl +
				'api/file/fileDownloadCommon.do?path=' +
				res.url;
		},
		querySearch(queryString, cb) {
			cb(this.restaurants);
		},
		querySearch1(queryString, cb) {
			cb(this.RepaymentMethod);
		},
		async getFinancialProductList() {
			const res = await financialProductList({
				financialOrganizationsId:
					this.$store.state.userInfo.financialOrganizationsId || -1,
				pageSize: 9999,
				pageNo: 1,
			});
			this.restaurants = res.list.map((item) => ({
				value: item.financialProductName,
			}));
		},
		formatCurrency(num, b = false) {
			if (num === 0) {
				return '0.00';
			}
			if (num) {
				if (typeof num != 'number' && b) return num;
				// 将num中的$,去掉，将num变成一个纯粹的数据格式字符串
				num = num.toString().replace(/\$|\,/g, '');
				// 如果num不是数字，则将num置0，并返回
				if ('' == num || isNaN(num)) {
					return 'Not a Number ! ';
				}
				// 如果num是负数，则获取她的符号
				var sign = '';
				if (num.indexOf('-') === 0) {
					sign = '-';
					num = num.substring(1);
				}
				// 如果存在小数点，则获取数字的小数部分
				var cents = num.indexOf('.') > 0 ? num.substr(num.indexOf('.')) : '';
				cents = cents.length > 1 ? cents : '.00';
				// 获取数字的整数数部分
				num = num.indexOf('.') > 0 ? num.substring(0, num.indexOf('.')) : num;
				// 针对整数部分进行格式化处理
				for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
					num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
				}
				// 将数据（符号、整数部分、小数部分）整体组合返回
				return sign + num + cents;
			}
		},
		hideIndex(data) {
			if (JSON.parse(data).length > 1) {
				return true;
			} else {
				return false;
			}
		},
		fn(str = '') {
			return str.replace(/\n/g, '<br />');
		},
		open() {
			// this.ruleForm = this.allData.clientPresidentRelatedVO;
			// if (this.paramsId.dealStatus > 2) {
			// 	this.getReadCooperationFeedback({
			// 		clientPresidentRelatedId:
			// 			this.paramsId.clientPresidentRelatedId,
			// 	});
			// }
			this.getFinancialProductList();

			setTimeout(() => {
				if (
					this.allData.clientPresidentRelatedVO &&
					this.allData.clientPresidentRelatedVO
						.clientPresidentRelatedId
				) {
					this.getReadCooperationFeedback({
						clientPresidentRelatedId:
							this.allData.clientPresidentRelatedVO
								.clientPresidentRelatedId,
					});
				}
			}, 1000);
			this.getItemByType();
			this.getClientBankFlowList();
		},
		async getClientBankFlowList() {
			const res = await clientBankFlowList(this.paramsId);
			this.isShowRunning = res;
		},
		async getItemByType() {
			const res = await itemByType({
				dictionaryType: 'RepaymentMethod',
			});
			this.RepaymentMethod = res.data.map((item) => ({
				value: item.itemName,
			}));
		},
		async getReadCooperationFeedback(data) {
			const res = await readCooperationFeedback(data);
			res.cooperateWillingness = res.cooperateWillingness
				? res.cooperateWillingness + ''
				: '';
			this.ruleForm = res;
		},
		arraySpanMethod({ row, column, rowIndex, columnIndex }) {
			if (
				rowIndex == 1 ||
				rowIndex == 21 ||
				rowIndex == 25 ||
				rowIndex == 29
			) {
				return {
					rowspan: 1,
					colspan: this.tableData.length + 1,
				};
			}
		},
		arraySpanMethod1({ row, column, rowIndex, columnIndex }) {
			// console.log(row, column, rowIndex, columnIndex);
			if (rowIndex == 1 || rowIndex == 6 || rowIndex == 9) {
				return {
					rowspan: 1,
					colspan: this.tableData.length + 1,
				};
			}
		},
		isRed1({ row, rowIndex }) {
			if (rowIndex === 0) {
				return {
					'background-color': '#ecf5ff !important',
					position: 'sticky',
					top: '0',
					'z-index': '1',
				};
			}
			// if (
			// 	rowIndex == 1 ||
			// 	rowIndex == 21 ||
			// 	rowIndex == 25 ||
			// 	rowIndex == 29
			// ) {
			// 	return {
			// 		'font-weight': ' 800',
			// 	};
			// }
		},
		isRed2({ row, rowIndex }) {
			if (rowIndex === 0) {
				return {
					'background-color': '#ecf5ff !important',
					position: 'sticky',
					top: '0',
					'z-index': '1',
				};
			}
			if (rowIndex == 1 || rowIndex == 6 || rowIndex == 9) {
				return {
					'font-weight': ' 800',
				};
			}
		},
		postCooperationFeedbackSave() {
			this.$refs['MyruleForm'].validate(async (valid) => {
				if (valid) {
					const res = await cooperationFeedbackSave(this.ruleForm);
					if (res.returncode == 0) {
						this.$confirm('提交成功', '提示', {
							confirmButtonText: '确定',
							type: 'success',
							showCancelButton: false,
						});
						this.$emit('closeVisible');
					} else {
						this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							type: 'error',
							showCancelButton: false,
						});
					}
				}
			});
		},
	},
	created() { },
	computed: {
		drawer_: {
			get() {
				return this.dialogVisible;
			},
			set(v) {
				this.$emit('update:dialogVisible', v);
			},
		},
		getHeaders() {
			const subjects = JSON.parse(JSON.stringify(this.allData.subjects));
			while (subjects.length < 4) {
				//2、判断循环条件;
				subjects.push({});
			}
			return subjects.reduce(
				(pre, cur, index) => pre.concat(`value${index}`),
				['title']
			);
		},
		allMoney() {
			if (
				this.allData.capitalizesList &&
				this.allData.capitalizesList.length
			) {
				let capitalizeAmount = 0;
				let loanBalance = 0;
				this.allData.capitalizesList.forEach((element) => {
					capitalizeAmount =
						capitalizeAmount + element.capitalizeAmount * 1000;
					loanBalance = loanBalance + element.loanBalance * 1000;
				});
				return `(融资总额：${capitalizeAmount / 1000}万元，贷款余额
							：${loanBalance / 1000}万元）`;
			} else {
				return ``;
			}
		},
		getValues() {
			return this.headers.map((item) => {
				return this.allData.balanceSheetList.reduce(
					(pre, cur, index) =>
						Object.assign(pre, {
							['value' + index]: this.formatCurrency(
								cur[item.prop],
								true
							),
						}),
					{ title: item.label }
				);
			});
		},
		getIncome() {
			return this.income.map((item) => {
				return this.allData.profitSheetList.reduce(
					(pre, cur, index) =>
						Object.assign(pre, {
							['value' + index]: this.formatCurrency(
								cur[item.prop],
								true
							),
						}),
					{ title: item.label }
				);
			});
		},
		getIndicator() {
			return this.indicator.map((item) => {
				if (this.allData.quotas) {
					return this.allData.quotas.reduce(
						(pre, cur, index) =>
							Object.assign(pre, {
								['value' + index]: this.formatCurrency(
									cur[item.prop],
									true
								),
							}),
						{ title: item.label }
					);
				} else {
					return { title: item.label };
				}
			});
		},
		getIndicatorHeaders() {
			const quotas = JSON.parse(JSON.stringify(this.allData.quotas));
			while (quotas.length < 4) {
				//2、判断循环条件;
				quotas.push({});
			}
			return quotas.reduce(
				(pre, cur, index) => pre.concat(`value${index}`),
				['title']
			);
		},
	},
	watch: {
		'ruleForm.cooperateWillingness'(val) {
			if (this.$refs['MyruleForm']) {
				this.$refs['MyruleForm'].clearValidate();
			}
			// if ((val = 3)) {
			// 	console.log(val);
			// 	this.$refs['MyruleForm'].resetFields();
			// }
		},
		allData() {
			if (this.allData.financeRequirementList) {
				if (this.allData.clientBase && this.allData.clientBase.creditCode) {
					this.getgetVfqClinetData(this.allData.clientBase.creditCode)
				} else {
					if (this.allData.financeRequirementList.length >= 1) {
						this.$nextTick(() => {
							if (
								!Array.from(document.querySelectorAll('.t1')).length
							) {
								this.isData = false;
							}
							Array.from(document.querySelectorAll('.t1')).forEach(
								(item, index) => {
									Array.from(
										document.querySelectorAll(
											`.${item.querySelector('span').className
											}`
										)
									).forEach((items, indexs) => {
										if (indexs) {
											items.innerHTML = `${index + 1
												}.${indexs}.`;
										} else {
											items.innerHTML = `${index + 1}.`;
										}
									});
									Array.from(
										document.querySelectorAll(
											`.${item.querySelector('span').className
											}-1-1`
										)
									).forEach((items, indexs) => {
										items.innerHTML = `${index + 1}.1.${indexs + 1
											}.`;
									});
								}
							);
						});
					} else {
						this.$nextTick(() => {
							if (
								!Array.from(document.querySelectorAll('.t1')).length
							) {
								this.isData = false;
							}
							Array.from(document.querySelectorAll('.t1')).forEach(
								(item, index) => {
									Array.from(
										document.querySelectorAll(
											`.${item.querySelector('span').className
											}`
										)
									).forEach((items, indexs) => {
										if (indexs) {
											items.innerHTML = `${index + 1
												}.${indexs}.`;
										} else {
											items.innerHTML = `${index + 1}.`;
										}
									});
									Array.from(
										document.querySelectorAll(
											`.${item.querySelector('span').className
											}-1-1`
										)
									).forEach((items, indexs) => {
										items.innerHTML = `${index + 1}.1.${indexs + 1
											}.`;
									});
								}
							);
						});
					}
				}

				this.getSpanArr(this.allData.riskScreeningInformationList);
				this.getSpanArr1(
					this.allData.competitivenessScreeningInformationList
				);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__header {
	margin-bottom: 0px !important;
}

::v-deep .diytable tbody tr[style] td {
	text-align: center !important;
}

::v-deep .diytable {
	overflow: hidden;
}

.diytable_box {
	.el-table--border {
		border-top: none;
	}

	.el-table::before {
		width: 0%;
	}

	.el-table--border::after {
		width: 0%;
	}
}

::v-deep .diytable_weight tr td:first-child {
	padding-left: 20px;
}

::v-deep .diytable_weight tr:nth-child(2) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight tr:nth-child(14) td:first-child {
	font-weight: 800;
	padding-left: 40px;
}

::v-deep .diytable_weight tr:nth-child(15) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight tr:nth-child(33) td:first-child {
	font-weight: 800;
}

::v-deep .diytable_weight tr:nth-child(34) td:first-child {
	font-weight: 800;
	padding-left: 40px;
}

::v-deep .diytable_weight tr:nth-child(35) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight tr:nth-child(48) td:first-child {
	font-weight: 800;
	padding-left: 40px;
}

::v-deep .diytable_weight tr:nth-child(49) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight tr:nth-child(65) td:first-child {
	font-weight: 800;
	padding-left: 40px;
}

::v-deep .diytable_weight tr:nth-child(66) td:first-child {
	font-weight: 800;
	padding-left: 20px;
}

::v-deep .diytable_weight1 tr td:first-child {
	padding-left: 20px;
}

::v-deep .diytable_weight1 tr:nth-child(2) td:first-child {
	font-weight: 800;
	padding-left: 0;
}

::v-deep .diytable_weight1 tr:nth-child(4) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(5) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(6) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(7) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(8) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(10) td:first-child {
	padding-left: 40px;
}

::v-deep .diytable_weight1 tr:nth-child(11) td:first-child {
	padding-left: 60px;
}

::v-deep .diytable_weight1 tr:nth-child(12) td:first-child {
	padding-left: 0px;
	font-weight: 800;
}

::v-deep .diytable_weight1 tr:nth-child(15) td:first-child {
	padding-left: 60px;
}

::v-deep .diytable_weight1 tr:nth-child(16) td:first-child {
	padding-left: 0px;
	font-weight: 800;
}

::v-deep .diytable_weight1 tr:nth-child(18) td:first-child {
	padding-left: 0px;
	font-weight: 800;
}

::v-deep .diytable_weight1 tr:nth-child(19) td:first-child {
	padding-left: 0px;
	font-weight: 800;
}

.box-card {
	margin-bottom: 10px;
}

::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 20px 0;
}

.form1 ::v-deep .el-form-item {
	margin-bottom: 0;
	padding-left: 20px;
}

.t1,
.t2,
.t3 {
	color: #3e31ff;
	font-weight: 800;
	margin: 10px 0;
}

.t1 {
	font-size: 20px;
}

.t2 {
	font-size: 18px;
	text-indent: 10px;
}

.t3 {
	font-size: 16px;
	text-indent: 20px;
}

.text-item {
	text-indent: 40px;
	margin: 20px 0;
}

::v-deep .el-input-number__increase {
	display: none;
}

::v-deep .el-input-number__decrease {
	display: none;
}

::v-deep .el-input-number input {
	text-align: left !important;
}

.info {
	line-height: 22px;
	margin: 10px 15px 20px 20px;
	text-indent: 0px;
}

::v-deep .el-upload-list__item:first-child {
	margin-top: 5px !important;
}

.diytable_box {
	border-right: 1px solid #ebeef5;
}

::v-deep .add-list>div label {
	opacity: 0;
}

::v-deep .add-list>div:first-child label {
	opacity: 1;
}

.box-item span {
	margin-right: 30px;
}

::v-deep .el-descriptions th {
	width: 25%;
}

.el-descriptions {
	margin-bottom: 20px;
}

::v-deep .diytable1 tbody tr:nth-child(2) td {
	opacity: 0 !important;
}

::v-deep .diytable1 tbody tr:nth-child(2) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable1 tbody tr:nth-child(22) td {
	opacity: 0 !important;
}

::v-deep .diytable1 tbody tr:nth-child(22) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable1 tbody tr:nth-child(26) td {
	opacity: 0 !important;
}

::v-deep .diytable1 tbody tr:nth-child(26) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable1 tbody tr:nth-child(30) td {
	opacity: 0 !important;
}

::v-deep .diytable1 tbody tr:nth-child(30) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable2 tbody tr:nth-child(2) td {
	opacity: 0 !important;
}

::v-deep .diytable2 tbody tr:nth-child(2) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable2 tbody tr:nth-child(7) td {
	opacity: 0 !important;
}

::v-deep .diytable2 tbody tr:nth-child(7) td:first-child {
	opacity: 1 !important;
}

::v-deep .diytable2 tbody tr:nth-child(10) td {
	opacity: 0 !important;
}

::v-deep .diytable2 tbody tr:nth-child(10) td:first-child {
	opacity: 1 !important;
}

::v-deep .el-form-item__label {
	font-weight: 800;
}

.table-title {
	font-size: 18px;
	font-weight: 700;
	color: #333;
	text-align: center;
	margin-bottom: 10px;
}

::v-deep .dztextl .el-form-item__label {
	text-align: left
}
.tcinfobox {
	margin-top: -35px;
}
</style>
